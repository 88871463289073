import React, { Suspense, useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { Outlet, useNavigate } from 'react-router-dom';

import { userState } from '@shared/recoil/user';
import { AccountRole } from '@shared/graphql/graphql';
import { getAccessToken, parseJwt, removeAccessToken } from '@shared/utils/user';

export default function WholesalerPrivateRoute() {
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();

  const isValidState = useCallback(() => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      console.error('no access token');
      return false;
    }

    const parsedToken = parseJwt(accessToken);

    if (!parsedToken) {
      console.error('invalid access token');
      return false;
    }

    if (parsedToken.role === AccountRole.Retailer) {
      console.error('invalid user role');
      return false;
    }

    setUser({ userId: String(parsedToken.user_id), role: parsedToken.role });

    return true;
  }, [setUser]);

  useEffect(() => {
    if (!isValidState()) {
      removeAccessToken();
      navigate('/wholesale/signin');
    }
  }, [isValidState, navigate]);

  return (
    <Suspense fallback={<div />}>
      <Outlet />
    </Suspense>
  );
}
