import React from 'react';
import { Outlet } from 'react-router-dom';

import TextHeader from '../header/TextHeader';
import BottomNav from '../header/navigation/BottomNav';

export default function MyPageLayout() {
  // 마이페이지 레이아웃
  return (
    <div className="w-full h-screen flex flex-col">
      <TextHeader>마이페이지</TextHeader>
      <main className="flex-1 overflow-y-auto">
        <Outlet />
      </main>
      <BottomNav />
    </div>
  );
}
