import { useSetRecoilState } from 'recoil';

import { modalState } from '@shared/recoil/modal';

export const useModal = () => {
  const setModal = useSetRecoilState(modalState);

  const openModal = (modal: JSX.Element) => {
    setModal(modal);
  };

  const closeModal = () => {
    setModal(null);
  };

  return {
    openModal,
    closeModal,
  };
};
