import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface ModalProps {
  className?: string;
  children: ReactNode;
}

export default function Toast({ className, children }: ModalProps) {
  return (
    <div
      className={classNames(
        'absolute left-0 bottom-0 w-full h-max min-h-[74px] flex-center p-4',
        className,
      )}
    >
      <div
        className={classNames(
          'w-full h-full flex-center m-4 bg-gray-700 py-6',
          'text-white title-3 rounded-16 text-center whitespace-pre-line',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
}
