import React from 'react';
import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import Apollo from './shared/apollo';

import './index.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_ENV === 'production' ? 'GTM-PHWQJDW' : 'GTM-MRXX57C',
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter basename="/">
    <Apollo>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Apollo>
  </BrowserRouter>,
);
