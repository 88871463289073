import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from '../components/layout/Layout';
import MyPageLayout from '../components/layout/MyPageLayout';
import SubPageLayout from '../components/layout/SubPageLayout';
import MainPageLayout from '../components/layout/MainPageLayout';
import RetailerPrivateRoute from '../components/route/RetailerPrivateRoute';
import WholesalerPrivateRoute from '../components/route/WholesalerPrivateRoute';

import { AccountRole } from '@shared/graphql/graphql';
import { getAccessToken, parseJwt } from '@shared/utils/user';
import { EUserRole } from '@shared/constants/user';
import { HeaderTitle } from '@src/shared/constants/title';

// 공통
const SignInPage = lazy(() => import('../pages/signin'));
const SignUpPage = lazy(() => import('../pages/signup'));

// 소매
const CartPage = lazy(() => import('../pages/retail/cart'));
const OrderPage = lazy(() => import('../pages/retail/order'));
const MyPagePage = lazy(() => import('../pages/retail/mypage'));
const IntroducePage = lazy(() => import('../pages/retail/introduce'));
const IntroduceWholesalerPage = lazy(() => import('../pages/retail/introduce/wholesaler'));
const WholesalersListPage = lazy(() => import('../pages/retail/wholesalers/list'));
const OrderSearchPage = lazy(() => import('../pages/retail/order/search'));
const MyPageOrderPage = lazy(() => import('../pages/retail/mypage/order'));
const MyPageCustomerPage = lazy(() => import('../pages/retail/mypage/customer'));
const OrderSearchResultsPage = lazy(() => import('../pages/retail/order/search/results'));
const MyPageOrderDetailsPage = lazy(() => import('../pages/retail/mypage/order/details'));

// 도매
// FIXME: 파일 분리
const WholesaleMyPagePage = lazy(() => import('../pages/wholesale/mypage'));
const WholesaleOrderListPage = lazy(() => import('../pages/wholesale/order/list'));
const WholesaleOrderTotalPage = lazy(() => import('../pages/wholesale/order/total'));
const WholesaleProductsListPage = lazy(() => import('../pages/wholesale/products/list'));
const WholesaleProductsDetailsPage = lazy(() => import('../pages/wholesale/products/details'));
const WholesaleMyPageGuidePage = lazy(() => import('../pages/wholesale/mypage/guide'));
const WholesaleMyPageOrderPage = lazy(() => import('../pages/wholesale/mypage/order'));
const WholesaleMyPageCustomerPage = lazy(() => import('../pages/wholesale/mypage/customer'));
const WholesaleOrderDetailsPage = lazy(() => import('../pages/wholesale/order/list/details'));
const WholesaleOrderPricingPage = lazy(() => import('../pages/wholesale/order/total/pricing'));
const WholesaleMyPageOrderListPage = lazy(() => import('../pages/wholesale/mypage/order/list'));
const WholesaleMyPageOrderListDetailsPage = lazy(
  () => import('../pages/wholesale/mypage/order/list/details'),
);

const getHomeRoute = (role: string) => {
  const accessToken = getAccessToken();
  const user = accessToken ? parseJwt(accessToken ?? '') : '';

  if (!user) {
    return `/${role}/signin`;
  } else if (user.role === AccountRole.Retailer) {
    return '/retail/order';
  } else {
    return '/wholesale/order/total';
  }
};

export default function Routers() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate replace to={getHomeRoute(EUserRole.RETAIL)} />} />
        <Route path="retail/signin" element={<SignInPage />} />
        <Route path="wholesale/signin" element={<SignInPage />} />

        <Route element={<SubPageLayout />}>
          <Route path="retail/signup" element={<SignUpPage />} />
          <Route path="wholesale/signup" element={<SignUpPage />} />
        </Route>

        {/* 소매상 */}
        <Route path="retail" element={<RetailerPrivateRoute />}>
          <Route path="*" element={<Navigate replace to={getHomeRoute(EUserRole.RETAIL)} />} />

          {/* 레이아웃 없는 페이지 */}
          <Route path="introduce" element={<IntroducePage />} />
          <Route path="introduce/wholesaler" element={<IntroduceWholesalerPage />} />

          {/* 메인 페이지 */}
          <Route element={<MainPageLayout title={HeaderTitle.PREORDER} />}>
            <Route path="order" element={<OrderPage />} />
          </Route>

          {/* 메인 페이지 */}
          <Route element={<MainPageLayout title={HeaderTitle.SALES_STATUS} />}>
            <Route path="wholesalers/list" element={<WholesalersListPage />} />
          </Route>

          {/* 마이페이지 */}
          <Route element={<MyPageLayout />}>
            <Route path="mypage" element={<MyPagePage />} />
          </Route>

          {/* 서브 페이지 */}
          <Route element={<SubPageLayout />}>
            <Route path="cart" element={<CartPage />} />
            <Route path="order/search" element={<OrderSearchPage />} />
            <Route path="order/search/results" element={<OrderSearchResultsPage />} />
            <Route path="mypage/order" element={<MyPageOrderPage />} />
            <Route path="mypage/customer" element={<MyPageCustomerPage />} />
            <Route path="mypage/order/details/:id" element={<MyPageOrderDetailsPage />} />
          </Route>
        </Route>

        {/* 도매상 */}
        <Route path="wholesale" element={<WholesalerPrivateRoute />}>
          <Route path="*" element={<Navigate replace to={getHomeRoute(EUserRole.WHOLESALE)} />} />

          {/* 메인 페이지 */}
          <Route element={<MainPageLayout title={HeaderTitle.TODAY_ORDER} />}>
            <Route path="order/total" element={<WholesaleOrderTotalPage />} />
            <Route path="order/list" element={<WholesaleOrderListPage />} />
            <Route path="*" element={<Navigate replace to={'order/total'} />} />
          </Route>
          <Route element={<MainPageLayout title={HeaderTitle.PRODUCTS_MANAGEMENT} />}>
            <Route path="products/list" element={<WholesaleProductsListPage />} />
          </Route>
          <Route element={<MainPageLayout title={HeaderTitle.MY_PAGE} />}>
            <Route path="mypage" element={<WholesaleMyPagePage />} />
          </Route>

          {/* 서브 페이지 */}
          <Route element={<SubPageLayout />}>
            <Route path="order/total/pricing" element={<WholesaleOrderPricingPage />} />
            <Route path="order/list/details/:id" element={<WholesaleOrderDetailsPage />} />
            <Route path="products/details/:id" element={<WholesaleProductsDetailsPage />} />
            <Route path="mypage/guide" element={<WholesaleMyPageGuidePage />} />
            <Route path="mypage/customer" element={<WholesaleMyPageCustomerPage />} />
            <Route path="mypage/order" element={<WholesaleMyPageOrderPage />} />
            <Route path="mypage/order/list" element={<WholesaleMyPageOrderListPage />} />
            <Route
              path="mypage/order/list/details/:id"
              element={<WholesaleMyPageOrderListDetailsPage />}
            />
          </Route>
        </Route>
      </Route>
      <Route path={'/*'} element={<Navigate replace to={getHomeRoute(EUserRole.RETAIL)} />} />
    </Routes>
  );
}
