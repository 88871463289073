import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { EUserRole } from '../constants/user';

export const usePageRole = () => {
  const { pathname } = useLocation();

  const isRetailer = useMemo(() => {
    return pathname.includes(EUserRole.RETAIL);
  }, [pathname]);

  return { isRetailer };
};
