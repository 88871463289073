import React from 'react';

import Cart from './cart/CartContainer';

interface TextHeaderProps {
  children: string;
}

export default function TextHeader({ children }: TextHeaderProps) {
  return (
    <header className="relative header flex pr-1">
      <h4 className="font-bold flex-1">{children}</h4>
      <div className="absolute right-0">
        <Cart />
      </div>
    </header>
  );
}
