import React from 'react';

import NavListItem from './NavListItem';
import { ReactComponent as Cart } from '@icons/cart.svg';
import { ReactComponent as User } from '@icons/user.svg';
import { ReactComponent as Flower } from '@icons/flower.svg';
import { ReactComponent as Magnifier } from '@icons/magnifier.svg';

import { usePageRole } from '@shared/hooks/role';

export default function BottomNav() {
  const { isRetailer } = usePageRole();

  return (
    <nav className="print:hidden">
      <ul className="bottom-0 flex border-t border-gray-100">
        <NavListItem
          icon={<Cart />}
          text={isRetailer ? '선주문' : '오늘의 주문'}
          link={isRetailer ? '/retail/order' : '/wholesale/order/total'}
        />
        {isRetailer ? (
          <NavListItem icon={<Magnifier />} text={'판매현황'} link={'wholesalers/list'} />
        ) : (
          <NavListItem icon={<Flower />} text={'상품관리'} link={'products/list'} />
        )}
        <NavListItem icon={<User />} text="마이페이지" link={`mypage`} />
      </ul>
    </nav>
  );
}
