import React from 'react';
import { Outlet } from 'react-router-dom';

import BottomNav from '../header/navigation/BottomNav';
import TextHeader from '../header/TextHeader';

interface MainPageLayoutProps {
  title: string;
}

export default function MainPageLayout({ title }: MainPageLayoutProps) {
  // 바텀 네비게이션 있는 페이지 레이아웃
  return (
    <div className="w-full h-screen flex flex-col print:h-auto">
      {/* FIXME: 페이지별 타이틀 */}
      <TextHeader>{title}</TextHeader>
      <main className="flex-1 overflow-y-auto">
        <Outlet />
      </main>
      <BottomNav />
    </div>
  );
}
