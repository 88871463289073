import React from 'react';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

import { getAccessToken, removeAccessToken } from '../utils/user';
import { API_URL, DEV_API_URL } from '../constants/url';

export const API_ADDRESS = process.env.REACT_APP_ENV === 'production' ? API_URL : DEV_API_URL;

/**
 * Apllo
 */
const httpLink = new HttpLink({
  uri: API_ADDRESS,
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: any) => {
    const token = getAccessToken() || '';

    return {
      headers: {
        authorization: `Bearer ${token}`,
        ...headers,
      },
    };
  });
  return forward(operation);
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      console.log('@@onErrorLink', message, path);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // TODO: 토큰만료 시 reissueToken 처리
    removeAccessToken();
    window.location.replace('/retail/signin');
  }
});

const additiveLink = from([retryLink, authLink, onErrorLink, httpLink]);

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

interface IApolloProps {
  children: JSX.Element;
}

function Apollo({ children }: IApolloProps): JSX.Element {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;
