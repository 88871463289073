import classNames from 'classnames';
import React, { ReactNode, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavListItemProps {
  icon: ReactNode;
  text: string;
  link: string;
}
export default function NavListItem({ icon, link, text }: NavListItemProps) {
  const { pathname } = useLocation();

  const isActive = useMemo(() => pathname.includes(link), [pathname, link]);

  return (
    <li className="flex-1 p-[10px]">
      <Link
        to={link}
        className={classNames('flex flex-col items-center', {
          '[&>svg>path]:fill-primary-500 [&>svg>g>path]:fill-primary-500 text-primary-500':
            isActive,
        })}
      >
        {icon}
        <span>{text}</span>
      </Link>
    </li>
  );
}
