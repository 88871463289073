export const QS_KEY_DATE = 'date';

export const KAKAO_CHANNEL_URL = 'http://pf.kakao.com/_rFjrxj';
export const NOTION_GUIDE_URL =
  'https://marketbill.notion.site/MarketBill-df205db40b414c7b91888bd70bcba572';
export const API_URL = 'https://api.marketbill.co.kr/graphql';
export const DEV_API_URL = 'https://api.dev.marketbill.co.kr/graphql';

export const DEFAULT_RECTANGLE_IMAGE_URL =
  'https://marketbill-storage.s3.ap-northeast-2.amazonaws.com/assets/dev/banners/prepare_image_list.png';
export const DEFAULT_SQUARE_IMAGE_URL =
  'https://marketbill-storage.s3.ap-northeast-2.amazonaws.com/assets/dev/banners/prepare_image_sqaure_list.png';
