import { atom } from 'recoil';

import { AccountRole } from '../graphql/graphql';

interface User {
  userId: string;
  role: AccountRole;
}

export const userState = atom<User | null>({
  key: 'userState',
  default: null,
});
