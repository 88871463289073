import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import Toast from './Toast';

import { toastState } from '@shared/recoil/toast';

export default function ToastContainer() {
  const toast = useRecoilValue(toastState);

  return <Fragment>{toast && <Toast>{toast}</Toast>}</Fragment>;
}
