import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDate: any;
  _FieldSet: any;
};

/**   역할 및 권한(소매상1, 도매상2) */
export enum AccountRole {
  /**  소매상 */
  Retailer = 'RETAILER',
  /**  도매상 직원 */
  WholesalerEmpe = 'WHOLESALER_EMPE',
  /**  도매상 점주 */
  WholesalerEmpr = 'WHOLESALER_EMPR'
}

export type AddToCartInput = {
  flowerId: Scalars['Int'];
  grade: FlowerGrade;
  memo?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

/**  거래처 신청 상태 */
export enum ApplyStatus {
  /**  신청중 */
  Applying = 'APPLYING',
  /**  승인 */
  Confirmed = 'CONFIRMED',
  /**  거부 */
  Rejected = 'REJECTED'
}

export type AuctionResult = {
  __typename?: 'AuctionResult';
  auctionDate: Scalars['Int'];
  boxCount: Scalars['Int'];
  flowerCount: Scalars['Int'];
  flowerGrade: Scalars['String'];
  flowerName: Scalars['String'];
  flowerTypeName: Scalars['String'];
  id: Scalars['Int'];
  /**  경매일자 YYYYMMDD */
  images: Array<Scalars['String']>;
  /**  도매상 설정 가격(null 인경우 미설정 상태) */
  isSoldOut: Scalars['Boolean'];
  price: Scalars['Int'];
  retailPrice?: Maybe<Scalars['Int']>;
  serialCode: Scalars['String'];
  shipper: Scalars['String'];
  totalPrice: Scalars['Int'];
  wholesalerId: Scalars['Int'];
};

export type AuctionResultDetailFilterInput = {
  id: Scalars['Int'];
};

export type AuctionResultDetailOutput = {
  __typename?: 'AuctionResultDetailOutput';
  item: AuctionResult;
};

export type AuctionResultFilterInput = {
  /**  "yyyy-MM-dd" 포맷 날짜 문자열(default 현재 날짜) */
  auctionDate?: InputMaybe<Scalars['String']>;
  /**  이전 n일까지 조회(default 3일) */
  beforeDays?: InputMaybe<Scalars['Int']>;
  wholesalerId: Scalars['Int'];
};

export type AuctionResultFlowerGradeInfo = {
  __typename?: 'AuctionResultFlowerGradeInfo';
  flowerGrade: Scalars['String'];
  id: Scalars['Int'];
};

export type AuctionResultForSaleDetailFilterInput = {
  id: Scalars['Int'];
};

export type AuctionResultForSaleDetailOutput = {
  __typename?: 'AuctionResultForSaleDetailOutput';
  item: AuctionResult;
};

export type AuctionResultForSaleFilterInput = {
  wholesalerId: Scalars['Int'];
};

export type AuctionResultForSaleOutput = PaginationOutput & {
  __typename?: 'AuctionResultForSaleOutput';
  items: Array<AuctionResultWithGroupBy>;
  resultCount: Scalars['Int'];
  wholesalerId: Scalars['Int'];
};

export type AuctionResultUpdateFilterInput = {
  id: Scalars['Int'];
  isSoldOut?: InputMaybe<Scalars['Boolean']>;
  retailPrice?: InputMaybe<Scalars['Int']>;
};

export type AuctionResultUpdateOutput = {
  __typename?: 'AuctionResultUpdateOutput';
  item: AuctionResult;
};

export type AuctionResultWithGroupBy = {
  __typename?: 'AuctionResultWithGroupBy';
  auctionDate: Scalars['Int'];
  flowerGradeInfos: Array<Maybe<AuctionResultFlowerGradeInfo>>;
  flowerName: Scalars['String'];
  flowerTypeName: Scalars['String'];
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  retailPrice?: Maybe<Scalars['Int']>;
};

export type AuctionResultsOutput = PaginationOutput & {
  __typename?: 'AuctionResultsOutput';
  items: Array<AuctionResult>;
  resultCount: Scalars['Int'];
};

/**  AuthTokenDto와 동일 */
export type AuthToken = {
  __typename?: 'AuthToken';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type BiddingFlower = {
  __typename?: 'BiddingFlower';
  biddingDate: Scalars['LocalDate'];
  flower: Flower;
  id: Scalars['Int'];
};

/**  소매상과 도매상의 거래처 관계 */
export type BizConnection = {
  __typename?: 'BizConnection';
  /**  거래처 신청 상태 */
  applyStatus: ApplyStatus;
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  id: Scalars['Int'];
  /**  거래처 신청자(=소매상) */
  retailer?: Maybe<User>;
  updatedAt: Scalars['LocalDate'];
  /**  거래처 신청 수취자(=도매상) */
  wholesaler?: Maybe<User>;
};

export type BizConnectionFilterInput = {
  applyStatus: Array<ApplyStatus>;
};

/**  본인이 속한 업체의 비즈니스 정보 */
export type BusinessInfo = {
  __typename?: 'BusinessInfo';
  address: Scalars['String'];
  /**  계좌(양식 = '<은행> <계좌번호> <예금주>') */
  bankAccount: Scalars['String'];
  /**  업태 */
  businessMainCategory: Scalars['String'];
  /**  사업자번호 */
  businessNo: Scalars['String'];
  /**  종목 */
  businessSubCategory: Scalars['String'];
  companyName: Scalars['String'];
  companyPhoneNo: Scalars['String'];
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  /**  대표자명 */
  employerName: Scalars['String'];
  id: Scalars['Int'];
  /**  인감도장 */
  sealStampImgUrl: Scalars['String'];
  updatedAt: Scalars['LocalDate'];
};

export type CartItem = {
  __typename?: 'CartItem';
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  flower: Flower;
  grade: FlowerGrade;
  id: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  orderedAt?: Maybe<Scalars['LocalDate']>;
  quantity: Scalars['Int'];
  retailer?: Maybe<User>;
  updatedAt: Scalars['LocalDate'];
  wholesaler?: Maybe<User>;
};

export type CartItemsOutput = PaginationOutput & {
  __typename?: 'CartItemsOutput';
  items: Array<CartItem>;
  resultCount: Scalars['Int'];
};

export type CommonResponse = {
  __typename?: 'CommonResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateBusinessInfoInput = {
  /**  회사주소 */
  address: Scalars['String'];
  /**  계좌 */
  bankAccount: Scalars['String'];
  /**  업태 */
  businessMainCategory: Scalars['String'];
  /**  사업자번호 */
  businessNo: Scalars['String'];
  /**  종목 */
  businessSubCategory: Scalars['String'];
  /**  회사명 */
  companyName: Scalars['String'];
  /**  회사번호 */
  companyPhoneNo: Scalars['String'];
  /**  대표자명 */
  employerName: Scalars['String'];
  /**  인감도장 */
  sealStampImgUrl: Scalars['String'];
  userId: Scalars['Int'];
};

/**  고객이 직접 입력하여 추가한 꽃 품목 */
export type CustomOrderItem = {
  __typename?: 'CustomOrderItem';
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  flowerName?: Maybe<Scalars['String']>;
  flowerTypeName?: Maybe<Scalars['String']>;
  grade?: Maybe<FlowerGrade>;
  id: Scalars['Int'];
  orderSheet: OrderSheet;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  retailer?: Maybe<User>;
  updatedAt: Scalars['LocalDate'];
  wholesaler?: Maybe<User>;
};

export type CustomOrderItemInput = {
  /**  Null(Insert), NotNull(Update) */
  flowerName?: InputMaybe<Scalars['String']>;
  flowerTypeName?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<FlowerGrade>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type DailyOrderItem = {
  __typename?: 'DailyOrderItem';
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  flower: Flower;
  grade: FlowerGrade;
  id: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['LocalDate'];
  wholesaler?: Maybe<User>;
};

export type DailyOrderItemFilterInput = {
  dateRange?: InputMaybe<DateRangeInput>;
};

export type DateFilterInput = {
  /**  'yyyy-MM-dd' 포맷 날짜 문자열 */
  date: Scalars['String'];
};

export type DateRangeInput = {
  /**  "yyyy-MM-dd" 포맷 날짜 문자열 */
  fromDate: Scalars['String'];
  /**  "yyyy-MM-dd" 포맷 날짜 문자열 */
  toDate: Scalars['String'];
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

/** # -------------- TYPE ------------------ */
export type Flower = {
  __typename?: 'Flower';
  /**  꽃의 경매 일자 목록(진행했던 경매일자들) */
  biddingFlowers: Array<BiddingFlower>;
  flowerColor?: Maybe<FlowerColor>;
  flowerType: FlowerType;
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type FlowerColor = {
  __typename?: 'FlowerColor';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FlowerFilterInput = {
  /**  경매 기간별 설정(from ~ to) */
  dateRange?: InputMaybe<DateRangeInput>;
  /**  검색용 키워드 */
  keyword?: InputMaybe<Scalars['String']>;
};

/**  꽃 등급 */
export enum FlowerGrade {
  /**  하 */
  Lower = 'LOWER',
  /**  중 */
  Middle = 'MIDDLE',
  /**  상 */
  Upper = 'UPPER'
}

export type FlowerType = {
  __typename?: 'FlowerType';
  flowers?: Maybe<Array<Flower>>;
  id: Scalars['Int'];
  imgUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type FlowersOutput = PaginationOutput & {
  __typename?: 'FlowersOutput';
  items: Array<Flower>;
  resultCount: Scalars['Int'];
};

/** # -------------- MUTATION ------------------ */
export type Mutation = {
  __typename?: 'Mutation';
  /**  [소매상] 장바구니 담기 */
  addToCart: CartItem;
  /**  소매상 -> 도매상 거래처 신청 */
  applyBizConnection: BizConnection;
  /**  [도매상] 영수증 발행 */
  issueOrderSheetReceipt: OrderSheetReceipt;
  /**  정기적 일괄 장바구니 주문처리 */
  orderBatchCartItems: CommonResponse;
  /**  [소매상] 장바구니 항목들 주문하기 */
  orderCartItems?: Maybe<OrderSheet>;
  /**  토큰 재발급(리토큰) */
  reissueToken: AuthToken;
  /**  [소매상] 장바구니 항목 삭제 */
  removeCartItem: CommonResponse;
  /**  [도매상] 커스텀 주문항목 삭제 */
  removeCustomOrderItem: CommonResponse;
  /**  [소매상] 주문서 삭제하기(주문 취소) */
  removeOrderSheet: CommonResponse;
  /**  [관리자] 유저 삭제 */
  removeUser: CommonResponse;
  /**  자유양식 SMS 전송 */
  sendDefaultSms: CommonResponse;
  /**  인증문자 SMS 전송 */
  sendVerificationSms: CommonResponse;
  /**  로그인 */
  signIn: AuthToken;
  /**  로그아웃 */
  signOut: CommonResponse;
  /**  회원가입 */
  signUp: AuthToken;
  /**  경매 매물 업데이트(도매상용) */
  updateAuctionResult: AuctionResultUpdateOutput;
  /**  도매상 -> 소매상 거래처 신청처리(승낙, 거절) */
  updateBizConnection: BizConnection;
  /**  [소매상] 장바구니 항목 수정 */
  updateCartItem: CartItem;
  /**  [도매상] 판매가 일괄적용 주문 항목 일괄 수정 */
  updateDailyOrderItemsPrice: Array<DailyOrderItem>;
  /**  [도매상] 주문내역 주문 항목 가격 수정 */
  updateOrderItemsPrice: Array<OrderItem>;
  /**  [관리자] 비밀번호 변경 */
  updatePassword: CommonResponse;
  /**  [소매상] 장바구니 쇼핑 세션값(도매상, 메모) 수정 */
  updateShoppingSession: ShoppingSession;
  /**  [관리자] 업체 정보 업로드 및 수정 */
  upsertBusinessInfo: BusinessInfo;
  /**  [도매상] 커스텀 주문항목 추가 */
  upsertCustomOrderItems: Array<CustomOrderItem>;
  /**
   *  [소매상] 장바구니 거래처 설정 및 수정
   * @deprecated upsertWholesalerOnCartItems is deprecated. Use 'updateShoppingSession' instead.
   */
  upsertWholesalerOnCartItems: Array<CartItem>;
};


/** # -------------- MUTATION ------------------ */
export type MutationAddToCartArgs = {
  input?: InputMaybe<AddToCartInput>;
};


/** # -------------- MUTATION ------------------ */
export type MutationApplyBizConnectionArgs = {
  wholesalerId: Scalars['Int'];
};


/** # -------------- MUTATION ------------------ */
export type MutationIssueOrderSheetReceiptArgs = {
  orderSheetId: Scalars['Int'];
};


/** # -------------- MUTATION ------------------ */
export type MutationRemoveCartItemArgs = {
  cartItemId: Scalars['Int'];
};


/** # -------------- MUTATION ------------------ */
export type MutationRemoveCustomOrderItemArgs = {
  customOrderItemIds: Array<Scalars['Int']>;
};


/** # -------------- MUTATION ------------------ */
export type MutationRemoveOrderSheetArgs = {
  orderSheetId: Scalars['Int'];
};


/** # -------------- MUTATION ------------------ */
export type MutationRemoveUserArgs = {
  userId: Scalars['Int'];
};


/** # -------------- MUTATION ------------------ */
export type MutationSendDefaultSmsArgs = {
  message: Scalars['String'];
  to: Scalars['String'];
};


/** # -------------- MUTATION ------------------ */
export type MutationSendVerificationSmsArgs = {
  code: Scalars['String'];
  to: Scalars['String'];
};


/** # -------------- MUTATION ------------------ */
export type MutationSignInArgs = {
  input?: InputMaybe<SignInInput>;
};


/** # -------------- MUTATION ------------------ */
export type MutationSignUpArgs = {
  input?: InputMaybe<SignUpInput>;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpdateAuctionResultArgs = {
  filter: AuctionResultUpdateFilterInput;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpdateBizConnectionArgs = {
  bizConnId: Scalars['Int'];
  status?: InputMaybe<ApplyStatus>;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpdateCartItemArgs = {
  input: UpdateCartItemInput;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpdateDailyOrderItemsPriceArgs = {
  items: Array<OrderItemPriceInput>;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpdateOrderItemsPriceArgs = {
  items: Array<OrderItemPriceInput>;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpdatePasswordArgs = {
  input?: InputMaybe<UpdatePasswordInput>;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpdateShoppingSessionArgs = {
  input: UpdateShoppingSessionInput;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpsertBusinessInfoArgs = {
  input?: InputMaybe<CreateBusinessInfoInput>;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpsertCustomOrderItemsArgs = {
  input: UpsertCustomOrderItemsInput;
};


/** # -------------- MUTATION ------------------ */
export type MutationUpsertWholesalerOnCartItemsArgs = {
  wholesalerId: Scalars['Int'];
};

export type OrderCartItemsInput = {
  cartItemIds: Array<Scalars['Int']>;
  wholesalerId: Scalars['Int'];
};

export type OrderItem = {
  __typename?: 'OrderItem';
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  flower: Flower;
  grade: FlowerGrade;
  id: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  orderSheet: OrderSheet;
  price?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  retailer?: Maybe<User>;
  updatedAt: Scalars['LocalDate'];
  wholesaler?: Maybe<User>;
};

export type OrderItemPriceInput = {
  /**  주문항목(orderItem, dailyOrderItem) ID */
  id: Scalars['Int'];
  /**  판매가 */
  price: Scalars['Int'];
};

export type OrderSheet = {
  __typename?: 'OrderSheet';
  createdAt: Scalars['LocalDate'];
  customOrderItems: Array<CustomOrderItem>;
  deletedAt?: Maybe<Scalars['LocalDate']>;
  /**  영수증 발행 여부 */
  hasReceipt: Scalars['Boolean'];
  id: Scalars['Int'];
  /**  판매가 저장여부(한번이라도 저장한 적이 있는지) */
  isPriceUpdated?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  orderItems: Array<OrderItem>;
  orderNo: Scalars['String'];
  orderSheetReceipts: Array<OrderSheetReceipt>;
  /**  최신 영수증(가장 근래 발행한 영수증) */
  recentReceipt?: Maybe<OrderSheetReceipt>;
  retailer?: Maybe<User>;
  /**  주문서에 해당하는 꽃의 총 금액 */
  totalFlowerPrice: Scalars['Int'];
  /**  주문서에 해당하는 꽃의 총 개수 */
  totalFlowerQuantity: Scalars['Int'];
  /**  주문서에 해당하는 꽃의 품종 개수(중복x) */
  totalFlowerTypeCount: Scalars['Int'];
  updatedAt: Scalars['LocalDate'];
  wholesaler?: Maybe<User>;
};


export type OrderSheetCustomOrderItemsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type OrderSheetOrderItemsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


export type OrderSheetOrderSheetReceiptsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type OrderSheetReceipt = {
  __typename?: 'OrderSheetReceipt';
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  /**  확장자명 */
  fileFormat: Scalars['String'];
  /**  파일명 */
  fileName: Scalars['String'];
  /**  파일경로 */
  filePath: Scalars['String'];
  id: Scalars['Int'];
  /**  그외 메타데이터 */
  metadata: Scalars['String'];
  orderSheet: OrderSheet;
  updatedAt: Scalars['LocalDate'];
};

/**  일자별 주문 집계 */
export type OrderSheetsAggregate = {
  __typename?: 'OrderSheetsAggregate';
  /**  날짜(date) */
  date?: Maybe<Scalars['LocalDate']>;
  /**  꽃 종류 개수(중복x) */
  flowerTypesCount: Scalars['Int'];
  /**  주문 수 */
  orderSheetsCount: Scalars['Int'];
};

export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  /**  기본 : 오름차순 */
  sort?: InputMaybe<Sort>;
};

export type PaginationOutput = {
  resultCount: Scalars['Int'];
};

/** # -------------- QUERY ------------------ */
export type Query = {
  __typename?: 'Query';
  _service: _Service;
  /**  경매 결과 리스트(도매상용) */
  auctionResult: AuctionResultsOutput;
  /**  경매 결과 세부(도매상용) */
  auctionResultDetail: AuctionResultDetailOutput;
  /**  경매 결과 리스트(소매상용) */
  auctionResultForSale: AuctionResultForSaleOutput;
  /**  경매 결과 세부(소매상용) */
  auctionResultForSaleDetail: AuctionResultForSaleDetailOutput;
  /**
   *  거래관계를 맺을 수 있는 유저 전체 목록 가져오기
   *  : 본인의 role 과 반대인 유저의 목록을 가져옵니다.
   */
  connectableUsers: Array<User>;
  currentDateTime?: Maybe<Scalars['LocalDate']>;
  /**  [도매상] 판매가 일괄적용시 불러오는 주문항목들 */
  dailyOrderItems: Array<DailyOrderItem>;
  /**
   *  [도매상] 오늘의 주문합계
   *  - date : "yyyy-MM-dd" 문자열 포맷(없는 경우 오늘 날짜 들어감)
   */
  dailyOrderSheetAggregate?: Maybe<OrderSheetsAggregate>;
  /**  [도매상] 일자별 주문 집계 목록 */
  dailyOrderSheetAggregates: Array<OrderSheetsAggregate>;
  /**
   *  꽃 목록 가져오기(기간별, 검색)
   *  - 사입가능한 꽃 목록(기간별)
   *  - 꽃 검색(검색)
   */
  flowers: FlowersOutput;
  /**
   *  [소매상] 장바구니 목록 가져오기
   * @deprecated getAllCartItems is deprecated. Use 'getShoppingSession' instead.
   */
  getAllCartItems: Array<CartItem>;
  /** @deprecated getAllDailyOrderSheetAggregates is deprecated. Use 'dailyOrderSheetAggregates' instead. */
  getAllDailyOrderSheetAggregates: Array<OrderSheetsAggregate>;
  /**
   *  [소매상] 장바구니 항목들에 기등록된 거래처 정보 가져오기
   * @deprecated getAllCartItems is deprecated. Use 'getShoppingSession' instead.
   */
  getCartWholesaler?: Maybe<User>;
  /** @deprecated getConnectableUsers is deprecated. Use 'connectableUsers' instead. */
  getConnectableUsers: Array<User>;
  /** @deprecated getDailyOrderItems is deprecated. Use 'dailyOrderItems' instead. */
  getDailyOrderItems: Array<DailyOrderItem>;
  /** @deprecated getDailyOrderSheetAggregate is deprecated. Use 'dailyOrderSheetAggregate' instead. */
  getDailyOrderSheetAggregate?: Maybe<OrderSheetsAggregate>;
  /** @deprecated getFlowers is deprecated. Use 'flowers' instead. */
  getFlowers: FlowersOutput;
  /** @deprecated getOrderItems is deprecated. Use 'orderItems' instead. */
  getOrderItems: Array<OrderItem>;
  /** @deprecated getOrderSheet is deprecated. Use 'orderSheet' instead. */
  getOrderSheet: OrderSheet;
  /** @deprecated getOrderSheets is deprecated. Use 'orderSheets' instead. */
  getOrderSheets: Array<OrderSheet>;
  /** @deprecated getShoppingSession is deprecated. Use 'shoppingSession' instead. */
  getShoppingSession?: Maybe<ShoppingSession>;
  /** @deprecated getUser is deprecated. Use 'user' instead. */
  getUser: User;
  /** @deprecated getUsers is deprecated. Use 'users' instead. */
  getUsers: Array<User>;
  /**
   *  토큰으로 나의 정보 가져오기
   *  - 거래처 목록 가져오기(내가 신청한 것만)
   */
  me: User;
  /**  [공통] 주문 항목 가져오기 */
  orderItems: Array<OrderItem>;
  /**  [공통] 주문서 단건(상세) 가져오기 */
  orderSheet: OrderSheet;
  /**  [공통] 주문서 목록 가져오기 */
  orderSheets: Array<OrderSheet>;
  /**  [소매상] 장바구니 쇼핑 세션 가져오기 */
  shoppingSession?: Maybe<ShoppingSession>;
  /**  [관리자] 단일 유저 정보 가져오기 */
  user: User;
  /**  유저 목록 가져오기 */
  users: Array<User>;
};


/** # -------------- QUERY ------------------ */
export type QueryAuctionResultArgs = {
  filter: AuctionResultFilterInput;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryAuctionResultDetailArgs = {
  filter: AuctionResultDetailFilterInput;
};


/** # -------------- QUERY ------------------ */
export type QueryAuctionResultForSaleArgs = {
  filter: AuctionResultForSaleFilterInput;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryAuctionResultForSaleDetailArgs = {
  filter: AuctionResultForSaleDetailFilterInput;
};


/** # -------------- QUERY ------------------ */
export type QueryConnectableUsersArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryDailyOrderItemsArgs = {
  filter?: InputMaybe<DailyOrderItemFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryDailyOrderSheetAggregateArgs = {
  date?: InputMaybe<Scalars['String']>;
};


/** # -------------- QUERY ------------------ */
export type QueryDailyOrderSheetAggregatesArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryFlowersArgs = {
  filter?: InputMaybe<FlowerFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetAllCartItemsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetAllDailyOrderSheetAggregatesArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetConnectableUsersArgs = {
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetDailyOrderItemsArgs = {
  filter?: InputMaybe<DailyOrderItemFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetDailyOrderSheetAggregateArgs = {
  date?: InputMaybe<Scalars['String']>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetFlowersArgs = {
  filter?: InputMaybe<FlowerFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetOrderItemsArgs = {
  filter?: InputMaybe<DateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetOrderSheetArgs = {
  orderSheetId: Scalars['Int'];
};


/** # -------------- QUERY ------------------ */
export type QueryGetOrderSheetsArgs = {
  filter?: InputMaybe<DateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


/** # -------------- QUERY ------------------ */
export type QueryGetUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryOrderItemsArgs = {
  filter?: InputMaybe<DateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryOrderSheetArgs = {
  orderSheetId: Scalars['Int'];
};


/** # -------------- QUERY ------------------ */
export type QueryOrderSheetsArgs = {
  filter?: InputMaybe<DateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/** # -------------- QUERY ------------------ */
export type QueryUserArgs = {
  id: Scalars['Int'];
};


/** # -------------- QUERY ------------------ */
export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type ShoppingSession = {
  __typename?: 'ShoppingSession';
  cartItems: CartItemsOutput;
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  id: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  retailer?: Maybe<User>;
  updatedAt: Scalars['LocalDate'];
  wholesaler?: Maybe<User>;
};


export type ShoppingSessionCartItemsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type SignInInput = {
  password: Scalars['String'];
  phoneNo: Scalars['String'];
};

/**  ----------------- INPUT ------------------ */
export type SignUpInput = {
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNo: Scalars['String'];
  role: AccountRole;
};

export enum Sort {
  /**  오름차순 */
  Ascend = 'ASCEND',
  /**  내림차순 */
  Descend = 'DESCEND'
}

export type UpdateCartItemInput = {
  grade: FlowerGrade;
  id: Scalars['Int'];
  memo?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

/**  실수 방지를 위해 userId, phoneNo 의 validation 진행 */
export type UpdatePasswordInput = {
  password: Scalars['String'];
  phoneNo: Scalars['String'];
  userId: Scalars['Int'];
};

export type UpdateShoppingSessionInput = {
  memo?: InputMaybe<Scalars['String']>;
  wholesalerId?: InputMaybe<Scalars['Int']>;
};

export type UpsertCustomOrderItemsInput = {
  items: Array<CustomOrderItemInput>;
  orderSheetId: Scalars['Int'];
};

/**  소매상, 도매상(대표, 직원) */
export type User = {
  __typename?: 'User';
  /**  보낸 거래처 신청 */
  appliedConnections: Array<BizConnection>;
  /**
   *  거래처 신청상태(토큰 내부 role에 기반하여 상대방과의 거래관계 상태값을 바로 가져옴)
   * @deprecated applyStatus is deprecated. Use 'connectableUsers(QUERY)' instead.
   */
  applyStatus?: Maybe<ApplyStatus>;
  /**  소속(도매상=시장명, 소매상=null) */
  belongsTo?: Maybe<Scalars['String']>;
  /**
   *  거래처 신청 테이블 고유값
   * @deprecated applyStatus is deprecated. Use 'connectableUsers(QUERY)' instead.
   */
  bizConnectionId?: Maybe<Scalars['Int']>;
  /**  본인이 속한 업체 비즈니스 정보 */
  businessInfo?: Maybe<BusinessInfo>;
  /**  [도매상] 연결된 직원 목록 */
  connectedEmployees: Array<User>;
  /**  [도매상] 연결된 사장(업체) */
  connectedEmployer?: Maybe<User>;
  deletedAt?: Maybe<Scalars['LocalDate']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /**  받은 거래처 신청 */
  receivedConnections: Array<BizConnection>;
  userCredential: UserCredential;
};


/**  소매상, 도매상(대표, 직원) */
export type UserAppliedConnectionsArgs = {
  filter?: InputMaybe<BizConnectionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


/**  소매상, 도매상(대표, 직원) */
export type UserReceivedConnectionsArgs = {
  filter?: InputMaybe<BizConnectionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

/**  유저의 로그인, 회원가입 정보 */
export type UserCredential = {
  __typename?: 'UserCredential';
  createdAt: Scalars['LocalDate'];
  deletedAt?: Maybe<Scalars['LocalDate']>;
  id: Scalars['Int'];
  phoneNo: Scalars['String'];
  /**  역할, 권한 */
  role: AccountRole;
  user: User;
};

export type UserFilterInput = {
  name?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<AccountRole>>;
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String'];
};

export type ApplyBizConnectionMutationVariables = Exact<{
  wholesalerId: Scalars['Int'];
}>;


export type ApplyBizConnectionMutation = { __typename?: 'Mutation', applyBizConnection: { __typename?: 'BizConnection', retailer?: { __typename?: 'User', id: number, name: string } | null | undefined, wholesaler?: { __typename?: 'User', id: number, name: string } | null | undefined } };

export type GetFlowersQueryVariables = Exact<{
  filter?: InputMaybe<FlowerFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetFlowersQuery = { __typename?: 'Query', getFlowers: { __typename?: 'FlowersOutput', resultCount: number, items: Array<{ __typename?: 'Flower', id: number, name: string, images: Array<string>, flowerType: { __typename?: 'FlowerType', name: string } }> } };

export type GetWholesalerSalesStatusQueryVariables = Exact<{
  filter: AuctionResultForSaleFilterInput;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetWholesalerSalesStatusQuery = { __typename?: 'Query', auctionResultForSale: { __typename?: 'AuctionResultForSaleOutput', resultCount: number, wholesalerId: number, items: Array<{ __typename?: 'AuctionResultWithGroupBy', id: number, flowerName: string, flowerTypeName: string, auctionDate: number, images: Array<string>, retailPrice?: number | null | undefined, flowerGradeInfos: Array<{ __typename?: 'AuctionResultFlowerGradeInfo', id: number, flowerGrade: string } | null | undefined> }> } };

export type AddToCartMutationVariables = Exact<{
  input?: InputMaybe<AddToCartInput>;
}>;


export type AddToCartMutation = { __typename?: 'Mutation', addToCart: { __typename?: 'CartItem', id: number, quantity: number, flower: { __typename?: 'Flower', name: string } } };

export type UpdateCartItemMutationVariables = Exact<{
  input: UpdateCartItemInput;
}>;


export type UpdateCartItemMutation = { __typename?: 'Mutation', updateCartItem: { __typename?: 'CartItem', id: number, quantity: number, grade: FlowerGrade, memo?: string | null | undefined } };

export type OrderCartItemsMutationVariables = Exact<{ [key: string]: never; }>;


export type OrderCartItemsMutation = { __typename?: 'Mutation', orderCartItems?: { __typename?: 'OrderSheet', id: number, orderNo: string, orderItems: Array<{ __typename?: 'OrderItem', id: number }> } | null | undefined };

export type RemoveCartItemMutationVariables = Exact<{
  cartItemId: Scalars['Int'];
}>;


export type RemoveCartItemMutation = { __typename?: 'Mutation', removeCartItem: { __typename?: 'CommonResponse', success?: boolean | null | undefined } };

export type UpdateShoppingSessionMutationVariables = Exact<{
  input: UpdateShoppingSessionInput;
}>;


export type UpdateShoppingSessionMutation = { __typename?: 'Mutation', updateShoppingSession: { __typename?: 'ShoppingSession', id: number, memo?: string | null | undefined, wholesaler?: { __typename?: 'User', name: string } | null | undefined } };

export type CancelOrderMutationVariables = Exact<{
  orderSheetId: Scalars['Int'];
}>;


export type CancelOrderMutation = { __typename?: 'Mutation', removeOrderSheet: { __typename?: 'CommonResponse', success?: boolean | null | undefined } };

export type AllCartItemsAndMemoQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
}>;


export type AllCartItemsAndMemoQuery = { __typename?: 'Query', getShoppingSession?: { __typename?: 'ShoppingSession', id: number, memo?: string | null | undefined, wholesaler?: { __typename?: 'User', id: number } | null | undefined, cartItems: { __typename?: 'CartItemsOutput', resultCount: number, items: Array<{ __typename?: 'CartItem', id: number, quantity: number, grade: FlowerGrade, memo?: string | null | undefined, flower: { __typename?: 'Flower', id: number, name: string, images: Array<string>, flowerType: { __typename?: 'FlowerType', name: string } } }> } } | null | undefined };

export type OrderSheetsQueryVariables = Exact<{
  filter?: InputMaybe<DateFilterInput>;
}>;


export type OrderSheetsQuery = { __typename?: 'Query', getOrderSheets: Array<{ __typename?: 'OrderSheet', id: number, hasReceipt: boolean, orderNo: string, memo?: string | null | undefined, totalFlowerQuantity: number, totalFlowerTypeCount: number, totalFlowerPrice: number, createdAt: any, wholesaler?: { __typename?: 'User', name: string } | null | undefined, retailer?: { __typename?: 'User', name: string } | null | undefined }> };

export type SendVerificationSmsMutationVariables = Exact<{
  to: Scalars['String'];
  code: Scalars['String'];
}>;


export type SendVerificationSmsMutation = { __typename?: 'Mutation', sendVerificationSms: { __typename?: 'CommonResponse', success?: boolean | null | undefined } };

export type SignInMutationVariables = Exact<{
  input?: InputMaybe<SignInInput>;
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'AuthToken', accessToken: string } };

export type SignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type SignOutMutation = { __typename?: 'Mutation', signOut: { __typename?: 'CommonResponse', success?: boolean | null | undefined } };

export type SignUpMutationVariables = Exact<{
  input?: InputMaybe<SignUpInput>;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: { __typename?: 'AuthToken', accessToken: string } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, belongsTo?: string | null | undefined, name: string, businessInfo?: { __typename?: 'BusinessInfo', businessNo: string } | null | undefined, userCredential: { __typename?: 'UserCredential', phoneNo: string } } };

export type MyWholesalersQueryVariables = Exact<{
  filter?: InputMaybe<BizConnectionFilterInput>;
}>;


export type MyWholesalersQuery = { __typename?: 'Query', me: { __typename?: 'User', appliedConnections: Array<{ __typename?: 'BizConnection', wholesaler?: { __typename?: 'User', id: number, name: string, belongsTo?: string | null | undefined, businessInfo?: { __typename?: 'BusinessInfo', companyPhoneNo: string } | null | undefined } | null | undefined }> } };

export type ConnectableWholesalersQueryVariables = Exact<{ [key: string]: never; }>;


export type ConnectableWholesalersQuery = { __typename?: 'Query', getConnectableUsers: Array<{ __typename?: 'User', id: number, name: string, belongsTo?: string | null | undefined, applyStatus?: ApplyStatus | null | undefined, bizConnectionId?: number | null | undefined }> };

export type UpdateAuctionResultMutationVariables = Exact<{
  filter: AuctionResultUpdateFilterInput;
}>;


export type UpdateAuctionResultMutation = { __typename?: 'Mutation', updateAuctionResult: { __typename?: 'AuctionResultUpdateOutput', item: { __typename?: 'AuctionResult', id: number, flowerName: string, flowerTypeName: string, flowerGrade: string, boxCount: number, flowerCount: number, price: number, totalPrice: number, serialCode: string, shipper: string, wholesalerId: number, auctionDate: number, images: Array<string>, retailPrice?: number | null | undefined, isSoldOut: boolean } } };

export type GetAuctionResultListQueryVariables = Exact<{
  filter: AuctionResultFilterInput;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetAuctionResultListQuery = { __typename?: 'Query', auctionResult: { __typename?: 'AuctionResultsOutput', resultCount: number, items: Array<{ __typename?: 'AuctionResult', id: number, flowerName: string, flowerTypeName: string, flowerGrade: string, boxCount: number, flowerCount: number, price: number, totalPrice: number, serialCode: string, shipper: string, wholesalerId: number, auctionDate: number, images: Array<string>, retailPrice?: number | null | undefined, isSoldOut: boolean }> } };

export type GetAuctionResultDetailsQueryVariables = Exact<{
  filter: AuctionResultDetailFilterInput;
}>;


export type GetAuctionResultDetailsQuery = { __typename?: 'Query', auctionResultDetail: { __typename?: 'AuctionResultDetailOutput', item: { __typename?: 'AuctionResult', id: number, flowerName: string, flowerTypeName: string, flowerGrade: string, boxCount: number, flowerCount: number, price: number, totalPrice: number, serialCode: string, shipper: string, wholesalerId: number, auctionDate: number, images: Array<string>, retailPrice?: number | null | undefined, isSoldOut: boolean } } };

export type UpdateApplyStatusMutationVariables = Exact<{
  bizConnId: Scalars['Int'];
  status?: InputMaybe<ApplyStatus>;
}>;


export type UpdateApplyStatusMutation = { __typename?: 'Mutation', updateBizConnection: { __typename?: 'BizConnection', id: number, applyStatus: ApplyStatus, retailer?: { __typename?: 'User', name: string } | null | undefined, wholesaler?: { __typename?: 'User', name: string } | null | undefined } };

export type GetWholesaleCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWholesaleCustomersQuery = { __typename?: 'Query', me: { __typename?: 'User', receivedConnections: Array<{ __typename?: 'BizConnection', id: number, applyStatus: ApplyStatus, retailer?: { __typename?: 'User', id: number, name: string, belongsTo?: string | null | undefined } | null | undefined }> } };

export type CartWholesalerQueryVariables = Exact<{ [key: string]: never; }>;


export type CartWholesalerQuery = { __typename?: 'Query', getShoppingSession?: { __typename?: 'ShoppingSession', wholesaler?: { __typename?: 'User', id: number, name: string } | null | undefined } | null | undefined };

export type UpdateDailyOrderItemsPriceMutationVariables = Exact<{
  items: Array<OrderItemPriceInput> | OrderItemPriceInput;
}>;


export type UpdateDailyOrderItemsPriceMutation = { __typename?: 'Mutation', updateDailyOrderItemsPrice: Array<{ __typename?: 'DailyOrderItem', id: number, grade: FlowerGrade, price?: number | null | undefined }> };

export type UpdateOrderItemsPriceMutationVariables = Exact<{
  items: Array<OrderItemPriceInput> | OrderItemPriceInput;
}>;


export type UpdateOrderItemsPriceMutation = { __typename?: 'Mutation', updateOrderItemsPrice: Array<{ __typename?: 'OrderItem', id: number, grade: FlowerGrade, price?: number | null | undefined }> };

export type IssueOrderSheetReceiptMutationVariables = Exact<{
  orderSheetId: Scalars['Int'];
}>;


export type IssueOrderSheetReceiptMutation = { __typename?: 'Mutation', issueOrderSheetReceipt: { __typename?: 'OrderSheetReceipt', id: number, fileFormat: string, filePath: string, metadata: string, orderSheet: { __typename?: 'OrderSheet', id: number } } };

export type UpsertCustomOrderItemsMutationVariables = Exact<{
  input: UpsertCustomOrderItemsInput;
}>;


export type UpsertCustomOrderItemsMutation = { __typename?: 'Mutation', upsertCustomOrderItems: Array<{ __typename?: 'CustomOrderItem', id: number, flowerName?: string | null | undefined, flowerTypeName?: string | null | undefined, quantity?: number | null | undefined, price?: number | null | undefined, grade?: FlowerGrade | null | undefined, orderSheet: { __typename?: 'OrderSheet', id: number } }> };

export type GetDailyTotalOrderAndItemsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetDailyTotalOrderAndItemsQuery = { __typename?: 'Query', getDailyOrderSheetAggregate?: { __typename?: 'OrderSheetsAggregate', date?: any | null | undefined, flowerTypesCount: number, orderSheetsCount: number } | null | undefined, getOrderItems: Array<{ __typename?: 'OrderItem', id: number, quantity: number, grade: FlowerGrade, price?: number | null | undefined, flower: { __typename?: 'Flower', name: string, images: Array<string>, flowerType: { __typename?: 'FlowerType', name: string } } }> };

export type DailyOrderItemsQueryVariables = Exact<{
  filter?: InputMaybe<DailyOrderItemFilterInput>;
}>;


export type DailyOrderItemsQuery = { __typename?: 'Query', getDailyOrderItems: Array<{ __typename?: 'DailyOrderItem', id: number, grade: FlowerGrade, price?: number | null | undefined, flower: { __typename?: 'Flower', name: string, images: Array<string>, flowerType: { __typename?: 'FlowerType', name: string } } }> };

export type GetDailyOrderSheetsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDailyOrderSheetsQuery = { __typename?: 'Query', getAllDailyOrderSheetAggregates: Array<{ __typename?: 'OrderSheetsAggregate', date?: any | null | undefined, orderSheetsCount: number, flowerTypesCount: number }> };

export type GetOrderSheetsQueryVariables = Exact<{
  filter?: InputMaybe<DateFilterInput>;
}>;


export type GetOrderSheetsQuery = { __typename?: 'Query', getOrderSheets: Array<{ __typename?: 'OrderSheet', id: number, hasReceipt: boolean, orderNo: string, createdAt: any, totalFlowerQuantity: number, retailer?: { __typename?: 'User', name: string } | null | undefined }> };

export type GetOrderSheetQueryVariables = Exact<{
  orderSheetId: Scalars['Int'];
}>;


export type GetOrderSheetQuery = { __typename?: 'Query', getOrderSheet: { __typename?: 'OrderSheet', id: number, memo?: string | null | undefined, hasReceipt: boolean, orderNo: string, createdAt: any, totalFlowerQuantity: number, totalFlowerTypeCount: number, totalFlowerPrice: number, isPriceUpdated?: boolean | null | undefined, customOrderItems: Array<{ __typename?: 'CustomOrderItem', id: number, flowerName?: string | null | undefined, flowerTypeName?: string | null | undefined, grade?: FlowerGrade | null | undefined, quantity?: number | null | undefined, price?: number | null | undefined }>, recentReceipt?: { __typename?: 'OrderSheetReceipt', id: number, filePath: string, fileFormat: string, fileName: string } | null | undefined, retailer?: { __typename?: 'User', name: string } | null | undefined, wholesaler?: { __typename?: 'User', name: string } | null | undefined, orderSheetReceipts: Array<{ __typename?: 'OrderSheetReceipt', id: number, filePath: string, fileFormat: string }>, orderItems: Array<{ __typename?: 'OrderItem', id: number, memo?: string | null | undefined, quantity: number, grade: FlowerGrade, price?: number | null | undefined, flower: { __typename?: 'Flower', name: string, images: Array<string>, flowerType: { __typename?: 'FlowerType', name: string } } }> } };

export type GetDailyOrderSheetQueryVariables = Exact<{
  filter?: InputMaybe<DateFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetDailyOrderSheetQuery = { __typename?: 'Query', getOrderSheets: Array<{ __typename?: 'OrderSheet', id: number, orderNo: string, createdAt: any, totalFlowerQuantity: number, retailer?: { __typename?: 'User', name: string } | null | undefined }> };

export type GetWholesalerOrderSheetQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWholesalerOrderSheetQuery = { __typename?: 'Query', getOrderSheet: { __typename?: 'OrderSheet', id: number, orderNo: string, createdAt: any, totalFlowerQuantity: number, retailer?: { __typename?: 'User', name: string } | null | undefined, orderItems: Array<{ __typename?: 'OrderItem', id: number, quantity: number, grade: FlowerGrade, price?: number | null | undefined, flower: { __typename?: 'Flower', name: string, images: Array<string>, flowerType: { __typename?: 'FlowerType', name: string } } }> } };


export const ApplyBizConnectionDocument = gql`
    mutation applyBizConnection($wholesalerId: Int!) {
  applyBizConnection(wholesalerId: $wholesalerId) {
    retailer {
      id
      name
    }
    wholesaler {
      id
      name
    }
  }
}
    `;
export type ApplyBizConnectionMutationFn = Apollo.MutationFunction<ApplyBizConnectionMutation, ApplyBizConnectionMutationVariables>;

/**
 * __useApplyBizConnectionMutation__
 *
 * To run a mutation, you first call `useApplyBizConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyBizConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyBizConnectionMutation, { data, loading, error }] = useApplyBizConnectionMutation({
 *   variables: {
 *      wholesalerId: // value for 'wholesalerId'
 *   },
 * });
 */
export function useApplyBizConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ApplyBizConnectionMutation, ApplyBizConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyBizConnectionMutation, ApplyBizConnectionMutationVariables>(ApplyBizConnectionDocument, options);
      }
export type ApplyBizConnectionMutationHookResult = ReturnType<typeof useApplyBizConnectionMutation>;
export type ApplyBizConnectionMutationResult = Apollo.MutationResult<ApplyBizConnectionMutation>;
export type ApplyBizConnectionMutationOptions = Apollo.BaseMutationOptions<ApplyBizConnectionMutation, ApplyBizConnectionMutationVariables>;
export const GetFlowersDocument = gql`
    query getFlowers($filter: FlowerFilterInput, $pagination: PaginationInput) {
  getFlowers(filter: $filter, pagination: $pagination) {
    resultCount
    items {
      id
      name
      flowerType {
        name
      }
      images
    }
  }
}
    `;

/**
 * __useGetFlowersQuery__
 *
 * To run a query within a React component, call `useGetFlowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlowersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetFlowersQuery(baseOptions?: Apollo.QueryHookOptions<GetFlowersQuery, GetFlowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlowersQuery, GetFlowersQueryVariables>(GetFlowersDocument, options);
      }
export function useGetFlowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlowersQuery, GetFlowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlowersQuery, GetFlowersQueryVariables>(GetFlowersDocument, options);
        }
export type GetFlowersQueryHookResult = ReturnType<typeof useGetFlowersQuery>;
export type GetFlowersLazyQueryHookResult = ReturnType<typeof useGetFlowersLazyQuery>;
export type GetFlowersQueryResult = Apollo.QueryResult<GetFlowersQuery, GetFlowersQueryVariables>;
export const GetWholesalerSalesStatusDocument = gql`
    query getWholesalerSalesStatus($filter: AuctionResultForSaleFilterInput!, $pagination: PaginationInput) {
  auctionResultForSale(filter: $filter, pagination: $pagination) {
    resultCount
    wholesalerId
    items {
      id
      flowerName
      flowerTypeName
      auctionDate
      images
      retailPrice
      flowerGradeInfos {
        id
        flowerGrade
      }
    }
  }
}
    `;

/**
 * __useGetWholesalerSalesStatusQuery__
 *
 * To run a query within a React component, call `useGetWholesalerSalesStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWholesalerSalesStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWholesalerSalesStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetWholesalerSalesStatusQuery(baseOptions: Apollo.QueryHookOptions<GetWholesalerSalesStatusQuery, GetWholesalerSalesStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWholesalerSalesStatusQuery, GetWholesalerSalesStatusQueryVariables>(GetWholesalerSalesStatusDocument, options);
      }
export function useGetWholesalerSalesStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWholesalerSalesStatusQuery, GetWholesalerSalesStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWholesalerSalesStatusQuery, GetWholesalerSalesStatusQueryVariables>(GetWholesalerSalesStatusDocument, options);
        }
export type GetWholesalerSalesStatusQueryHookResult = ReturnType<typeof useGetWholesalerSalesStatusQuery>;
export type GetWholesalerSalesStatusLazyQueryHookResult = ReturnType<typeof useGetWholesalerSalesStatusLazyQuery>;
export type GetWholesalerSalesStatusQueryResult = Apollo.QueryResult<GetWholesalerSalesStatusQuery, GetWholesalerSalesStatusQueryVariables>;
export const AddToCartDocument = gql`
    mutation addToCart($input: AddToCartInput) {
  addToCart(input: $input) {
    id
    quantity
    flower {
      name
    }
  }
}
    `;
export type AddToCartMutationFn = Apollo.MutationFunction<AddToCartMutation, AddToCartMutationVariables>;

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddToCartMutation(baseOptions?: Apollo.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToCartMutation, AddToCartMutationVariables>(AddToCartDocument, options);
      }
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>;
export type AddToCartMutationResult = Apollo.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = Apollo.BaseMutationOptions<AddToCartMutation, AddToCartMutationVariables>;
export const UpdateCartItemDocument = gql`
    mutation updateCartItem($input: UpdateCartItemInput!) {
  updateCartItem(input: $input) {
    id
    quantity
    grade
    memo
  }
}
    `;
export type UpdateCartItemMutationFn = Apollo.MutationFunction<UpdateCartItemMutation, UpdateCartItemMutationVariables>;

/**
 * __useUpdateCartItemMutation__
 *
 * To run a mutation, you first call `useUpdateCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartItemMutation, { data, loading, error }] = useUpdateCartItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCartItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCartItemMutation, UpdateCartItemMutationVariables>(UpdateCartItemDocument, options);
      }
export type UpdateCartItemMutationHookResult = ReturnType<typeof useUpdateCartItemMutation>;
export type UpdateCartItemMutationResult = Apollo.MutationResult<UpdateCartItemMutation>;
export type UpdateCartItemMutationOptions = Apollo.BaseMutationOptions<UpdateCartItemMutation, UpdateCartItemMutationVariables>;
export const OrderCartItemsDocument = gql`
    mutation orderCartItems {
  orderCartItems {
    id
    orderNo
    orderItems {
      id
    }
  }
}
    `;
export type OrderCartItemsMutationFn = Apollo.MutationFunction<OrderCartItemsMutation, OrderCartItemsMutationVariables>;

/**
 * __useOrderCartItemsMutation__
 *
 * To run a mutation, you first call `useOrderCartItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCartItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCartItemsMutation, { data, loading, error }] = useOrderCartItemsMutation({
 *   variables: {
 *   },
 * });
 */
export function useOrderCartItemsMutation(baseOptions?: Apollo.MutationHookOptions<OrderCartItemsMutation, OrderCartItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCartItemsMutation, OrderCartItemsMutationVariables>(OrderCartItemsDocument, options);
      }
export type OrderCartItemsMutationHookResult = ReturnType<typeof useOrderCartItemsMutation>;
export type OrderCartItemsMutationResult = Apollo.MutationResult<OrderCartItemsMutation>;
export type OrderCartItemsMutationOptions = Apollo.BaseMutationOptions<OrderCartItemsMutation, OrderCartItemsMutationVariables>;
export const RemoveCartItemDocument = gql`
    mutation removeCartItem($cartItemId: Int!) {
  removeCartItem(cartItemId: $cartItemId) {
    success
  }
}
    `;
export type RemoveCartItemMutationFn = Apollo.MutationFunction<RemoveCartItemMutation, RemoveCartItemMutationVariables>;

/**
 * __useRemoveCartItemMutation__
 *
 * To run a mutation, you first call `useRemoveCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCartItemMutation, { data, loading, error }] = useRemoveCartItemMutation({
 *   variables: {
 *      cartItemId: // value for 'cartItemId'
 *   },
 * });
 */
export function useRemoveCartItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCartItemMutation, RemoveCartItemMutationVariables>(RemoveCartItemDocument, options);
      }
export type RemoveCartItemMutationHookResult = ReturnType<typeof useRemoveCartItemMutation>;
export type RemoveCartItemMutationResult = Apollo.MutationResult<RemoveCartItemMutation>;
export type RemoveCartItemMutationOptions = Apollo.BaseMutationOptions<RemoveCartItemMutation, RemoveCartItemMutationVariables>;
export const UpdateShoppingSessionDocument = gql`
    mutation updateShoppingSession($input: UpdateShoppingSessionInput!) {
  updateShoppingSession(input: $input) {
    id
    wholesaler {
      name
    }
    memo
  }
}
    `;
export type UpdateShoppingSessionMutationFn = Apollo.MutationFunction<UpdateShoppingSessionMutation, UpdateShoppingSessionMutationVariables>;

/**
 * __useUpdateShoppingSessionMutation__
 *
 * To run a mutation, you first call `useUpdateShoppingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShoppingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShoppingSessionMutation, { data, loading, error }] = useUpdateShoppingSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShoppingSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShoppingSessionMutation, UpdateShoppingSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShoppingSessionMutation, UpdateShoppingSessionMutationVariables>(UpdateShoppingSessionDocument, options);
      }
export type UpdateShoppingSessionMutationHookResult = ReturnType<typeof useUpdateShoppingSessionMutation>;
export type UpdateShoppingSessionMutationResult = Apollo.MutationResult<UpdateShoppingSessionMutation>;
export type UpdateShoppingSessionMutationOptions = Apollo.BaseMutationOptions<UpdateShoppingSessionMutation, UpdateShoppingSessionMutationVariables>;
export const CancelOrderDocument = gql`
    mutation cancelOrder($orderSheetId: Int!) {
  removeOrderSheet(orderSheetId: $orderSheetId) {
    success
  }
}
    `;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderSheetId: // value for 'orderSheetId'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const AllCartItemsAndMemoDocument = gql`
    query allCartItemsAndMemo($pagination: PaginationInput) {
  getShoppingSession {
    id
    memo
    wholesaler {
      id
    }
    cartItems(pagination: $pagination) {
      resultCount
      items {
        id
        flower {
          id
          name
          flowerType {
            name
          }
          images
        }
        quantity
        grade
        memo
      }
    }
  }
}
    `;

/**
 * __useAllCartItemsAndMemoQuery__
 *
 * To run a query within a React component, call `useAllCartItemsAndMemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCartItemsAndMemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCartItemsAndMemoQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAllCartItemsAndMemoQuery(baseOptions?: Apollo.QueryHookOptions<AllCartItemsAndMemoQuery, AllCartItemsAndMemoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCartItemsAndMemoQuery, AllCartItemsAndMemoQueryVariables>(AllCartItemsAndMemoDocument, options);
      }
export function useAllCartItemsAndMemoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCartItemsAndMemoQuery, AllCartItemsAndMemoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCartItemsAndMemoQuery, AllCartItemsAndMemoQueryVariables>(AllCartItemsAndMemoDocument, options);
        }
export type AllCartItemsAndMemoQueryHookResult = ReturnType<typeof useAllCartItemsAndMemoQuery>;
export type AllCartItemsAndMemoLazyQueryHookResult = ReturnType<typeof useAllCartItemsAndMemoLazyQuery>;
export type AllCartItemsAndMemoQueryResult = Apollo.QueryResult<AllCartItemsAndMemoQuery, AllCartItemsAndMemoQueryVariables>;
export const OrderSheetsDocument = gql`
    query orderSheets($filter: DateFilterInput) {
  getOrderSheets(filter: $filter) {
    id
    hasReceipt
    orderNo
    memo
    totalFlowerQuantity
    totalFlowerTypeCount
    totalFlowerPrice
    wholesaler {
      name
    }
    retailer {
      name
    }
    createdAt
  }
}
    `;

/**
 * __useOrderSheetsQuery__
 *
 * To run a query within a React component, call `useOrderSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderSheetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrderSheetsQuery(baseOptions?: Apollo.QueryHookOptions<OrderSheetsQuery, OrderSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderSheetsQuery, OrderSheetsQueryVariables>(OrderSheetsDocument, options);
      }
export function useOrderSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderSheetsQuery, OrderSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderSheetsQuery, OrderSheetsQueryVariables>(OrderSheetsDocument, options);
        }
export type OrderSheetsQueryHookResult = ReturnType<typeof useOrderSheetsQuery>;
export type OrderSheetsLazyQueryHookResult = ReturnType<typeof useOrderSheetsLazyQuery>;
export type OrderSheetsQueryResult = Apollo.QueryResult<OrderSheetsQuery, OrderSheetsQueryVariables>;
export const SendVerificationSmsDocument = gql`
    mutation sendVerificationSms($to: String!, $code: String!) {
  sendVerificationSms(to: $to, code: $code) {
    success
  }
}
    `;
export type SendVerificationSmsMutationFn = Apollo.MutationFunction<SendVerificationSmsMutation, SendVerificationSmsMutationVariables>;

/**
 * __useSendVerificationSmsMutation__
 *
 * To run a mutation, you first call `useSendVerificationSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationSmsMutation, { data, loading, error }] = useSendVerificationSmsMutation({
 *   variables: {
 *      to: // value for 'to'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSendVerificationSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationSmsMutation, SendVerificationSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationSmsMutation, SendVerificationSmsMutationVariables>(SendVerificationSmsDocument, options);
      }
export type SendVerificationSmsMutationHookResult = ReturnType<typeof useSendVerificationSmsMutation>;
export type SendVerificationSmsMutationResult = Apollo.MutationResult<SendVerificationSmsMutation>;
export type SendVerificationSmsMutationOptions = Apollo.BaseMutationOptions<SendVerificationSmsMutation, SendVerificationSmsMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($input: SignInInput) {
  signIn(input: $input) {
    accessToken
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = gql`
    mutation signOut {
  signOut {
    success
  }
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($input: SignUpInput) {
  signUp(input: $input) {
    accessToken
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    belongsTo
    name
    businessInfo {
      businessNo
    }
    userCredential {
      phoneNo
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyWholesalersDocument = gql`
    query myWholesalers($filter: BizConnectionFilterInput) {
  me {
    appliedConnections(filter: $filter) {
      wholesaler {
        id
        name
        belongsTo
        businessInfo {
          companyPhoneNo
        }
      }
    }
  }
}
    `;

/**
 * __useMyWholesalersQuery__
 *
 * To run a query within a React component, call `useMyWholesalersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWholesalersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWholesalersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMyWholesalersQuery(baseOptions?: Apollo.QueryHookOptions<MyWholesalersQuery, MyWholesalersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyWholesalersQuery, MyWholesalersQueryVariables>(MyWholesalersDocument, options);
      }
export function useMyWholesalersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyWholesalersQuery, MyWholesalersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyWholesalersQuery, MyWholesalersQueryVariables>(MyWholesalersDocument, options);
        }
export type MyWholesalersQueryHookResult = ReturnType<typeof useMyWholesalersQuery>;
export type MyWholesalersLazyQueryHookResult = ReturnType<typeof useMyWholesalersLazyQuery>;
export type MyWholesalersQueryResult = Apollo.QueryResult<MyWholesalersQuery, MyWholesalersQueryVariables>;
export const ConnectableWholesalersDocument = gql`
    query connectableWholesalers {
  getConnectableUsers {
    id
    name
    belongsTo
    applyStatus
    bizConnectionId
  }
}
    `;

/**
 * __useConnectableWholesalersQuery__
 *
 * To run a query within a React component, call `useConnectableWholesalersQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectableWholesalersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectableWholesalersQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectableWholesalersQuery(baseOptions?: Apollo.QueryHookOptions<ConnectableWholesalersQuery, ConnectableWholesalersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectableWholesalersQuery, ConnectableWholesalersQueryVariables>(ConnectableWholesalersDocument, options);
      }
export function useConnectableWholesalersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectableWholesalersQuery, ConnectableWholesalersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectableWholesalersQuery, ConnectableWholesalersQueryVariables>(ConnectableWholesalersDocument, options);
        }
export type ConnectableWholesalersQueryHookResult = ReturnType<typeof useConnectableWholesalersQuery>;
export type ConnectableWholesalersLazyQueryHookResult = ReturnType<typeof useConnectableWholesalersLazyQuery>;
export type ConnectableWholesalersQueryResult = Apollo.QueryResult<ConnectableWholesalersQuery, ConnectableWholesalersQueryVariables>;
export const UpdateAuctionResultDocument = gql`
    mutation updateAuctionResult($filter: AuctionResultUpdateFilterInput!) {
  updateAuctionResult(filter: $filter) {
    item {
      id
      flowerName
      flowerTypeName
      flowerGrade
      boxCount
      flowerCount
      price
      totalPrice
      serialCode
      shipper
      wholesalerId
      auctionDate
      images
      retailPrice
      isSoldOut
    }
  }
}
    `;
export type UpdateAuctionResultMutationFn = Apollo.MutationFunction<UpdateAuctionResultMutation, UpdateAuctionResultMutationVariables>;

/**
 * __useUpdateAuctionResultMutation__
 *
 * To run a mutation, you first call `useUpdateAuctionResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuctionResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuctionResultMutation, { data, loading, error }] = useUpdateAuctionResultMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateAuctionResultMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuctionResultMutation, UpdateAuctionResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuctionResultMutation, UpdateAuctionResultMutationVariables>(UpdateAuctionResultDocument, options);
      }
export type UpdateAuctionResultMutationHookResult = ReturnType<typeof useUpdateAuctionResultMutation>;
export type UpdateAuctionResultMutationResult = Apollo.MutationResult<UpdateAuctionResultMutation>;
export type UpdateAuctionResultMutationOptions = Apollo.BaseMutationOptions<UpdateAuctionResultMutation, UpdateAuctionResultMutationVariables>;
export const GetAuctionResultListDocument = gql`
    query getAuctionResultList($filter: AuctionResultFilterInput!, $pagination: PaginationInput) {
  auctionResult(filter: $filter, pagination: $pagination) {
    resultCount
    items {
      id
      flowerName
      flowerTypeName
      flowerGrade
      boxCount
      flowerCount
      price
      totalPrice
      serialCode
      shipper
      wholesalerId
      auctionDate
      images
      retailPrice
      isSoldOut
    }
  }
}
    `;

/**
 * __useGetAuctionResultListQuery__
 *
 * To run a query within a React component, call `useGetAuctionResultListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionResultListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionResultListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAuctionResultListQuery(baseOptions: Apollo.QueryHookOptions<GetAuctionResultListQuery, GetAuctionResultListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuctionResultListQuery, GetAuctionResultListQueryVariables>(GetAuctionResultListDocument, options);
      }
export function useGetAuctionResultListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuctionResultListQuery, GetAuctionResultListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuctionResultListQuery, GetAuctionResultListQueryVariables>(GetAuctionResultListDocument, options);
        }
export type GetAuctionResultListQueryHookResult = ReturnType<typeof useGetAuctionResultListQuery>;
export type GetAuctionResultListLazyQueryHookResult = ReturnType<typeof useGetAuctionResultListLazyQuery>;
export type GetAuctionResultListQueryResult = Apollo.QueryResult<GetAuctionResultListQuery, GetAuctionResultListQueryVariables>;
export const GetAuctionResultDetailsDocument = gql`
    query getAuctionResultDetails($filter: AuctionResultDetailFilterInput!) {
  auctionResultDetail(filter: $filter) {
    item {
      id
      flowerName
      flowerTypeName
      flowerGrade
      boxCount
      flowerCount
      price
      totalPrice
      serialCode
      shipper
      wholesalerId
      auctionDate
      images
      retailPrice
      isSoldOut
    }
  }
}
    `;

/**
 * __useGetAuctionResultDetailsQuery__
 *
 * To run a query within a React component, call `useGetAuctionResultDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionResultDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionResultDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAuctionResultDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAuctionResultDetailsQuery, GetAuctionResultDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuctionResultDetailsQuery, GetAuctionResultDetailsQueryVariables>(GetAuctionResultDetailsDocument, options);
      }
export function useGetAuctionResultDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuctionResultDetailsQuery, GetAuctionResultDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuctionResultDetailsQuery, GetAuctionResultDetailsQueryVariables>(GetAuctionResultDetailsDocument, options);
        }
export type GetAuctionResultDetailsQueryHookResult = ReturnType<typeof useGetAuctionResultDetailsQuery>;
export type GetAuctionResultDetailsLazyQueryHookResult = ReturnType<typeof useGetAuctionResultDetailsLazyQuery>;
export type GetAuctionResultDetailsQueryResult = Apollo.QueryResult<GetAuctionResultDetailsQuery, GetAuctionResultDetailsQueryVariables>;
export const UpdateApplyStatusDocument = gql`
    mutation updateApplyStatus($bizConnId: Int!, $status: ApplyStatus) {
  updateBizConnection(bizConnId: $bizConnId, status: $status) {
    id
    applyStatus
    retailer {
      name
    }
    wholesaler {
      name
    }
  }
}
    `;
export type UpdateApplyStatusMutationFn = Apollo.MutationFunction<UpdateApplyStatusMutation, UpdateApplyStatusMutationVariables>;

/**
 * __useUpdateApplyStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApplyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplyStatusMutation, { data, loading, error }] = useUpdateApplyStatusMutation({
 *   variables: {
 *      bizConnId: // value for 'bizConnId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateApplyStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplyStatusMutation, UpdateApplyStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplyStatusMutation, UpdateApplyStatusMutationVariables>(UpdateApplyStatusDocument, options);
      }
export type UpdateApplyStatusMutationHookResult = ReturnType<typeof useUpdateApplyStatusMutation>;
export type UpdateApplyStatusMutationResult = Apollo.MutationResult<UpdateApplyStatusMutation>;
export type UpdateApplyStatusMutationOptions = Apollo.BaseMutationOptions<UpdateApplyStatusMutation, UpdateApplyStatusMutationVariables>;
export const GetWholesaleCustomersDocument = gql`
    query getWholesaleCustomers {
  me {
    receivedConnections {
      id
      retailer {
        id
        name
        belongsTo
      }
      applyStatus
    }
  }
}
    `;

/**
 * __useGetWholesaleCustomersQuery__
 *
 * To run a query within a React component, call `useGetWholesaleCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWholesaleCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWholesaleCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWholesaleCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetWholesaleCustomersQuery, GetWholesaleCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWholesaleCustomersQuery, GetWholesaleCustomersQueryVariables>(GetWholesaleCustomersDocument, options);
      }
export function useGetWholesaleCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWholesaleCustomersQuery, GetWholesaleCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWholesaleCustomersQuery, GetWholesaleCustomersQueryVariables>(GetWholesaleCustomersDocument, options);
        }
export type GetWholesaleCustomersQueryHookResult = ReturnType<typeof useGetWholesaleCustomersQuery>;
export type GetWholesaleCustomersLazyQueryHookResult = ReturnType<typeof useGetWholesaleCustomersLazyQuery>;
export type GetWholesaleCustomersQueryResult = Apollo.QueryResult<GetWholesaleCustomersQuery, GetWholesaleCustomersQueryVariables>;
export const CartWholesalerDocument = gql`
    query cartWholesaler {
  getShoppingSession {
    wholesaler {
      id
      name
    }
  }
}
    `;

/**
 * __useCartWholesalerQuery__
 *
 * To run a query within a React component, call `useCartWholesalerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartWholesalerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartWholesalerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartWholesalerQuery(baseOptions?: Apollo.QueryHookOptions<CartWholesalerQuery, CartWholesalerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartWholesalerQuery, CartWholesalerQueryVariables>(CartWholesalerDocument, options);
      }
export function useCartWholesalerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartWholesalerQuery, CartWholesalerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartWholesalerQuery, CartWholesalerQueryVariables>(CartWholesalerDocument, options);
        }
export type CartWholesalerQueryHookResult = ReturnType<typeof useCartWholesalerQuery>;
export type CartWholesalerLazyQueryHookResult = ReturnType<typeof useCartWholesalerLazyQuery>;
export type CartWholesalerQueryResult = Apollo.QueryResult<CartWholesalerQuery, CartWholesalerQueryVariables>;
export const UpdateDailyOrderItemsPriceDocument = gql`
    mutation updateDailyOrderItemsPrice($items: [OrderItemPriceInput!]!) {
  updateDailyOrderItemsPrice(items: $items) {
    id
    grade
    price
  }
}
    `;
export type UpdateDailyOrderItemsPriceMutationFn = Apollo.MutationFunction<UpdateDailyOrderItemsPriceMutation, UpdateDailyOrderItemsPriceMutationVariables>;

/**
 * __useUpdateDailyOrderItemsPriceMutation__
 *
 * To run a mutation, you first call `useUpdateDailyOrderItemsPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDailyOrderItemsPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDailyOrderItemsPriceMutation, { data, loading, error }] = useUpdateDailyOrderItemsPriceMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateDailyOrderItemsPriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDailyOrderItemsPriceMutation, UpdateDailyOrderItemsPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDailyOrderItemsPriceMutation, UpdateDailyOrderItemsPriceMutationVariables>(UpdateDailyOrderItemsPriceDocument, options);
      }
export type UpdateDailyOrderItemsPriceMutationHookResult = ReturnType<typeof useUpdateDailyOrderItemsPriceMutation>;
export type UpdateDailyOrderItemsPriceMutationResult = Apollo.MutationResult<UpdateDailyOrderItemsPriceMutation>;
export type UpdateDailyOrderItemsPriceMutationOptions = Apollo.BaseMutationOptions<UpdateDailyOrderItemsPriceMutation, UpdateDailyOrderItemsPriceMutationVariables>;
export const UpdateOrderItemsPriceDocument = gql`
    mutation updateOrderItemsPrice($items: [OrderItemPriceInput!]!) {
  updateOrderItemsPrice(items: $items) {
    id
    grade
    price
  }
}
    `;
export type UpdateOrderItemsPriceMutationFn = Apollo.MutationFunction<UpdateOrderItemsPriceMutation, UpdateOrderItemsPriceMutationVariables>;

/**
 * __useUpdateOrderItemsPriceMutation__
 *
 * To run a mutation, you first call `useUpdateOrderItemsPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderItemsPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderItemsPriceMutation, { data, loading, error }] = useUpdateOrderItemsPriceMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateOrderItemsPriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderItemsPriceMutation, UpdateOrderItemsPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderItemsPriceMutation, UpdateOrderItemsPriceMutationVariables>(UpdateOrderItemsPriceDocument, options);
      }
export type UpdateOrderItemsPriceMutationHookResult = ReturnType<typeof useUpdateOrderItemsPriceMutation>;
export type UpdateOrderItemsPriceMutationResult = Apollo.MutationResult<UpdateOrderItemsPriceMutation>;
export type UpdateOrderItemsPriceMutationOptions = Apollo.BaseMutationOptions<UpdateOrderItemsPriceMutation, UpdateOrderItemsPriceMutationVariables>;
export const IssueOrderSheetReceiptDocument = gql`
    mutation issueOrderSheetReceipt($orderSheetId: Int!) {
  issueOrderSheetReceipt(orderSheetId: $orderSheetId) {
    id
    orderSheet {
      id
    }
    fileFormat
    filePath
    metadata
  }
}
    `;
export type IssueOrderSheetReceiptMutationFn = Apollo.MutationFunction<IssueOrderSheetReceiptMutation, IssueOrderSheetReceiptMutationVariables>;

/**
 * __useIssueOrderSheetReceiptMutation__
 *
 * To run a mutation, you first call `useIssueOrderSheetReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueOrderSheetReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueOrderSheetReceiptMutation, { data, loading, error }] = useIssueOrderSheetReceiptMutation({
 *   variables: {
 *      orderSheetId: // value for 'orderSheetId'
 *   },
 * });
 */
export function useIssueOrderSheetReceiptMutation(baseOptions?: Apollo.MutationHookOptions<IssueOrderSheetReceiptMutation, IssueOrderSheetReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IssueOrderSheetReceiptMutation, IssueOrderSheetReceiptMutationVariables>(IssueOrderSheetReceiptDocument, options);
      }
export type IssueOrderSheetReceiptMutationHookResult = ReturnType<typeof useIssueOrderSheetReceiptMutation>;
export type IssueOrderSheetReceiptMutationResult = Apollo.MutationResult<IssueOrderSheetReceiptMutation>;
export type IssueOrderSheetReceiptMutationOptions = Apollo.BaseMutationOptions<IssueOrderSheetReceiptMutation, IssueOrderSheetReceiptMutationVariables>;
export const UpsertCustomOrderItemsDocument = gql`
    mutation upsertCustomOrderItems($input: UpsertCustomOrderItemsInput!) {
  upsertCustomOrderItems(input: $input) {
    id
    orderSheet {
      id
    }
    flowerName
    flowerTypeName
    quantity
    price
    grade
  }
}
    `;
export type UpsertCustomOrderItemsMutationFn = Apollo.MutationFunction<UpsertCustomOrderItemsMutation, UpsertCustomOrderItemsMutationVariables>;

/**
 * __useUpsertCustomOrderItemsMutation__
 *
 * To run a mutation, you first call `useUpsertCustomOrderItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCustomOrderItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCustomOrderItemsMutation, { data, loading, error }] = useUpsertCustomOrderItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCustomOrderItemsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCustomOrderItemsMutation, UpsertCustomOrderItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCustomOrderItemsMutation, UpsertCustomOrderItemsMutationVariables>(UpsertCustomOrderItemsDocument, options);
      }
export type UpsertCustomOrderItemsMutationHookResult = ReturnType<typeof useUpsertCustomOrderItemsMutation>;
export type UpsertCustomOrderItemsMutationResult = Apollo.MutationResult<UpsertCustomOrderItemsMutation>;
export type UpsertCustomOrderItemsMutationOptions = Apollo.BaseMutationOptions<UpsertCustomOrderItemsMutation, UpsertCustomOrderItemsMutationVariables>;
export const GetDailyTotalOrderAndItemsDocument = gql`
    query getDailyTotalOrderAndItems($date: String!) {
  getDailyOrderSheetAggregate {
    date
    flowerTypesCount
    orderSheetsCount
  }
  getOrderItems(filter: {date: $date}) {
    id
    flower {
      name
      flowerType {
        name
      }
      images
    }
    quantity
    grade
    price
  }
}
    `;

/**
 * __useGetDailyTotalOrderAndItemsQuery__
 *
 * To run a query within a React component, call `useGetDailyTotalOrderAndItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyTotalOrderAndItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyTotalOrderAndItemsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDailyTotalOrderAndItemsQuery(baseOptions: Apollo.QueryHookOptions<GetDailyTotalOrderAndItemsQuery, GetDailyTotalOrderAndItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyTotalOrderAndItemsQuery, GetDailyTotalOrderAndItemsQueryVariables>(GetDailyTotalOrderAndItemsDocument, options);
      }
export function useGetDailyTotalOrderAndItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyTotalOrderAndItemsQuery, GetDailyTotalOrderAndItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyTotalOrderAndItemsQuery, GetDailyTotalOrderAndItemsQueryVariables>(GetDailyTotalOrderAndItemsDocument, options);
        }
export type GetDailyTotalOrderAndItemsQueryHookResult = ReturnType<typeof useGetDailyTotalOrderAndItemsQuery>;
export type GetDailyTotalOrderAndItemsLazyQueryHookResult = ReturnType<typeof useGetDailyTotalOrderAndItemsLazyQuery>;
export type GetDailyTotalOrderAndItemsQueryResult = Apollo.QueryResult<GetDailyTotalOrderAndItemsQuery, GetDailyTotalOrderAndItemsQueryVariables>;
export const DailyOrderItemsDocument = gql`
    query dailyOrderItems($filter: DailyOrderItemFilterInput) {
  getDailyOrderItems(filter: $filter) {
    id
    flower {
      name
      flowerType {
        name
      }
      images
    }
    grade
    price
  }
}
    `;

/**
 * __useDailyOrderItemsQuery__
 *
 * To run a query within a React component, call `useDailyOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyOrderItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDailyOrderItemsQuery(baseOptions?: Apollo.QueryHookOptions<DailyOrderItemsQuery, DailyOrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyOrderItemsQuery, DailyOrderItemsQueryVariables>(DailyOrderItemsDocument, options);
      }
export function useDailyOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyOrderItemsQuery, DailyOrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyOrderItemsQuery, DailyOrderItemsQueryVariables>(DailyOrderItemsDocument, options);
        }
export type DailyOrderItemsQueryHookResult = ReturnType<typeof useDailyOrderItemsQuery>;
export type DailyOrderItemsLazyQueryHookResult = ReturnType<typeof useDailyOrderItemsLazyQuery>;
export type DailyOrderItemsQueryResult = Apollo.QueryResult<DailyOrderItemsQuery, DailyOrderItemsQueryVariables>;
export const GetDailyOrderSheetsDocument = gql`
    query getDailyOrderSheets {
  getAllDailyOrderSheetAggregates {
    date
    orderSheetsCount
    flowerTypesCount
  }
}
    `;

/**
 * __useGetDailyOrderSheetsQuery__
 *
 * To run a query within a React component, call `useGetDailyOrderSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyOrderSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyOrderSheetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDailyOrderSheetsQuery(baseOptions?: Apollo.QueryHookOptions<GetDailyOrderSheetsQuery, GetDailyOrderSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyOrderSheetsQuery, GetDailyOrderSheetsQueryVariables>(GetDailyOrderSheetsDocument, options);
      }
export function useGetDailyOrderSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyOrderSheetsQuery, GetDailyOrderSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyOrderSheetsQuery, GetDailyOrderSheetsQueryVariables>(GetDailyOrderSheetsDocument, options);
        }
export type GetDailyOrderSheetsQueryHookResult = ReturnType<typeof useGetDailyOrderSheetsQuery>;
export type GetDailyOrderSheetsLazyQueryHookResult = ReturnType<typeof useGetDailyOrderSheetsLazyQuery>;
export type GetDailyOrderSheetsQueryResult = Apollo.QueryResult<GetDailyOrderSheetsQuery, GetDailyOrderSheetsQueryVariables>;
export const GetOrderSheetsDocument = gql`
    query getOrderSheets($filter: DateFilterInput) {
  getOrderSheets(filter: $filter) {
    id
    hasReceipt
    orderNo
    createdAt
    totalFlowerQuantity
    retailer {
      name
    }
  }
}
    `;

/**
 * __useGetOrderSheetsQuery__
 *
 * To run a query within a React component, call `useGetOrderSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSheetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetOrderSheetsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderSheetsQuery, GetOrderSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderSheetsQuery, GetOrderSheetsQueryVariables>(GetOrderSheetsDocument, options);
      }
export function useGetOrderSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderSheetsQuery, GetOrderSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderSheetsQuery, GetOrderSheetsQueryVariables>(GetOrderSheetsDocument, options);
        }
export type GetOrderSheetsQueryHookResult = ReturnType<typeof useGetOrderSheetsQuery>;
export type GetOrderSheetsLazyQueryHookResult = ReturnType<typeof useGetOrderSheetsLazyQuery>;
export type GetOrderSheetsQueryResult = Apollo.QueryResult<GetOrderSheetsQuery, GetOrderSheetsQueryVariables>;
export const GetOrderSheetDocument = gql`
    query getOrderSheet($orderSheetId: Int!) {
  getOrderSheet(orderSheetId: $orderSheetId) {
    id
    memo
    hasReceipt
    customOrderItems {
      id
      flowerName
      flowerTypeName
      grade
      quantity
      price
    }
    recentReceipt {
      id
      filePath
      fileFormat
      fileName
    }
    orderNo
    createdAt
    totalFlowerQuantity
    totalFlowerTypeCount
    totalFlowerPrice
    retailer {
      name
    }
    wholesaler {
      name
    }
    orderSheetReceipts {
      id
      filePath
      fileFormat
    }
    orderItems {
      id
      memo
      flower {
        name
        flowerType {
          name
        }
        images
      }
      quantity
      grade
      price
    }
    isPriceUpdated
  }
}
    `;

/**
 * __useGetOrderSheetQuery__
 *
 * To run a query within a React component, call `useGetOrderSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderSheetQuery({
 *   variables: {
 *      orderSheetId: // value for 'orderSheetId'
 *   },
 * });
 */
export function useGetOrderSheetQuery(baseOptions: Apollo.QueryHookOptions<GetOrderSheetQuery, GetOrderSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderSheetQuery, GetOrderSheetQueryVariables>(GetOrderSheetDocument, options);
      }
export function useGetOrderSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderSheetQuery, GetOrderSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderSheetQuery, GetOrderSheetQueryVariables>(GetOrderSheetDocument, options);
        }
export type GetOrderSheetQueryHookResult = ReturnType<typeof useGetOrderSheetQuery>;
export type GetOrderSheetLazyQueryHookResult = ReturnType<typeof useGetOrderSheetLazyQuery>;
export type GetOrderSheetQueryResult = Apollo.QueryResult<GetOrderSheetQuery, GetOrderSheetQueryVariables>;
export const GetDailyOrderSheetDocument = gql`
    query getDailyOrderSheet($filter: DateFilterInput, $pagination: PaginationInput) {
  getOrderSheets(filter: $filter, pagination: $pagination) {
    id
    orderNo
    createdAt
    totalFlowerQuantity
    retailer {
      name
    }
  }
}
    `;

/**
 * __useGetDailyOrderSheetQuery__
 *
 * To run a query within a React component, call `useGetDailyOrderSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyOrderSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyOrderSheetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetDailyOrderSheetQuery(baseOptions?: Apollo.QueryHookOptions<GetDailyOrderSheetQuery, GetDailyOrderSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyOrderSheetQuery, GetDailyOrderSheetQueryVariables>(GetDailyOrderSheetDocument, options);
      }
export function useGetDailyOrderSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyOrderSheetQuery, GetDailyOrderSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyOrderSheetQuery, GetDailyOrderSheetQueryVariables>(GetDailyOrderSheetDocument, options);
        }
export type GetDailyOrderSheetQueryHookResult = ReturnType<typeof useGetDailyOrderSheetQuery>;
export type GetDailyOrderSheetLazyQueryHookResult = ReturnType<typeof useGetDailyOrderSheetLazyQuery>;
export type GetDailyOrderSheetQueryResult = Apollo.QueryResult<GetDailyOrderSheetQuery, GetDailyOrderSheetQueryVariables>;
export const GetWholesalerOrderSheetDocument = gql`
    query getWholesalerOrderSheet {
  getOrderSheet(orderSheetId: 1) {
    id
    orderNo
    createdAt
    totalFlowerQuantity
    retailer {
      name
    }
    orderItems {
      id
      flower {
        name
        flowerType {
          name
        }
        images
      }
      quantity
      grade
      price
    }
  }
}
    `;

/**
 * __useGetWholesalerOrderSheetQuery__
 *
 * To run a query within a React component, call `useGetWholesalerOrderSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWholesalerOrderSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWholesalerOrderSheetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWholesalerOrderSheetQuery(baseOptions?: Apollo.QueryHookOptions<GetWholesalerOrderSheetQuery, GetWholesalerOrderSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWholesalerOrderSheetQuery, GetWholesalerOrderSheetQueryVariables>(GetWholesalerOrderSheetDocument, options);
      }
export function useGetWholesalerOrderSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWholesalerOrderSheetQuery, GetWholesalerOrderSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWholesalerOrderSheetQuery, GetWholesalerOrderSheetQueryVariables>(GetWholesalerOrderSheetDocument, options);
        }
export type GetWholesalerOrderSheetQueryHookResult = ReturnType<typeof useGetWholesalerOrderSheetQuery>;
export type GetWholesalerOrderSheetLazyQueryHookResult = ReturnType<typeof useGetWholesalerOrderSheetLazyQuery>;
export type GetWholesalerOrderSheetQueryResult = Apollo.QueryResult<GetWholesalerOrderSheetQuery, GetWholesalerOrderSheetQueryVariables>;