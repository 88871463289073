import { useEffect, useState } from 'react';

import { OrderItemPriceInput } from '@shared/graphql/graphql';

export const useUpdatePriceInput = () => {
  const [itemsPriceInput, setItemsPrice] = useState<Array<OrderItemPriceInput>>([]);

  const clearItemsPriceInput = () => {
    setItemsPrice([]);
  };

  const changeUpdatePriceOnOrderItemsInput = (id: number, price: number) => {
    const existingItem = itemsPriceInput.filter((item) => item.id === id)[0];

    // TODO: price 0 이면 제거
    if (existingItem && price) {
      // 해당 id가 기존 itemsPrice에 있었던 경우, 해당 객체의 price만 변경
      const newItemsPrice = itemsPriceInput.map((item) => {
        if (item.id === id) {
          return {
            id,
            price,
          };
        } else {
          return item;
        }
      });
      setItemsPrice([...newItemsPrice]);
    } else if (existingItem && !price) {
      const priceInput = [...itemsPriceInput];
      const existingItemIndex = priceInput.indexOf(existingItem);
      priceInput.splice(existingItemIndex, 1);
      setItemsPrice([...priceInput]);
    } else if (price) {
      // 해당 id가 없었을 경우, 새로운 input 객체 생성
      const newItemPrice = {
        id,
        price,
      };
      setItemsPrice((prev) => [...prev, newItemPrice]);
    }
  };

  useEffect(() => {
    console.log('@@itemsPriceInput', itemsPriceInput);
  }, [itemsPriceInput]);

  return { itemsPriceInput, clearItemsPriceInput, changeUpdatePriceOnOrderItemsInput };
};
