import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';

import { useModal } from '@shared/hooks';
import { modalState } from '@shared/recoil';

export default function ModalContainer() {
  const { closeModal } = useModal();
  const modal = useRecoilValue(modalState);
  const onDimClick = () => {
    closeModal();
  };

  return (
    <Fragment>
      {modal && (
        <div>
          <div
            className="w-full h-full absolute left-0 top-0 bg-gray-900/70"
            onClick={onDimClick}
          />
          {modal}
        </div>
      )}
    </Fragment>
  );
}
