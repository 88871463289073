import { useNavigate } from 'react-router-dom';
import { EStorageKey } from '../constants/user';
import { AccountRole, useSignOutMutation } from '../graphql/graphql';

interface Token {
  user_id: number;
  role: AccountRole;
  exp: number;
}

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem(EStorageKey.ACCESS_TOKEN, accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(EStorageKey.ACCESS_TOKEN);
};

export const removeAccessToken = () => {
  localStorage.removeItem(EStorageKey.ACCESS_TOKEN);
};

export const makeVerifyCode = () => {
  return String(Math.floor(Math.random() * 1000000)).padEnd(6, '0');
};

export function parseJwt(token: string): Token | null {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}
