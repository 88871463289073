import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';

import CartComponent from './CartComponent';

import { userState } from '@shared/recoil/user';
import { AccountRole } from '@shared/graphql/graphql';

export default function Cart() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = useRecoilValue(userState);

  const goToCheckout = () => {
    // 판매현황 탭일 경우에는 사전 신청 페이지로 이동
    if (pathname.includes('wholesalers/list')) {
      window.open('https://marketbill-pre-registration-site.webflow.io/', '_blank');
    } else {
      navigate('cart');
    }
  };

  if (!user || user.role !== AccountRole.Retailer) {
    return <Fragment />;
  }

  return <CartComponent onCartClick={goToCheckout} />;
}
