import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SearchBar from '@components/search-bar/SearchBar';
import { ReactComponent as Arrow } from '@icons/arrow-left.svg';

export default function TopNav() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onArrowClick = () => {
    window.history.go(-1);
  };

  const getHeaderTextByPathname = (pathname: string) => {
    // FIXME: 최적화 고민하자 ,,
    if (pathname.includes('terms')) {
      return '서비스 이용 약관';
    } else if (pathname.includes('personal-info')) {
      return '개인정보 수집 및 이용';
    } else if (pathname.includes('signup')) {
      return '회원가입';
    } else if (pathname.includes('cart')) {
      return '장바구니';
    } else if (pathname.includes('guide')) {
      return '주문 집계 안내';
    } else if (pathname.includes('products')) {
      return '상품 상세보기';
    } else if (pathname.includes('details')) {
      return '주문상세';
    } else if (pathname.includes('pricing')) {
      return '판매가 일괄 입력';
    } else if (pathname.includes('retail/mypage/customer')) {
      return '거래처 등록';
    } else if (pathname.includes('wholesale/mypage/customer')) {
      return '거래처 관리';
    } else if (pathname.includes('order')) {
      return '주문내역';
    }
  };

  const onSearchBarClick = () => {
    if (pathname.includes('result')) {
      navigate(-1);
    }
  };

  return (
    <nav className="header relative flex-center px-1">
      {/* 뒤로가기 */}
      <Arrow className="absolute left-1 w-[48px] cursor-pointer" onClick={onArrowClick} />
      <div className="flex-1">
        {pathname.includes('search') ? (
          <div onClick={onSearchBarClick}>
            <SearchBar />
          </div>
        ) : (
          <div>
            <h4 className="inline-block font-bold">{getHeaderTextByPathname(pathname)}</h4>
          </div>
        )}
      </div>
    </nav>
  );
}
