import { useRecoilState } from 'recoil';

import { toastState } from '@shared/recoil/toast';

export const useToast = () => {
  const [existToast, setToast] = useRecoilState(toastState);

  const toast = (toast: string) => {
    if (existToast) {
      closeToast();
    }
    setToast(toast);
    setTimeout(closeToast, 3000);
  };

  const closeToast = () => {
    setToast(null);
  };

  return {
    toast,
  };
};
