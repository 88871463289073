import { useMemo } from 'react';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '../constants/format';
import {
  Sort,
  useMeQuery,
  ApplyStatus,
  useGetFlowersQuery,
  useOrderSheetsQuery,
  useMyWholesalersQuery,
  useGetOrderSheetQuery,
  useCartWholesalerQuery,
  useGetDailyOrderSheetsQuery,
  useGetWholesaleCustomersQuery,
  useConnectableWholesalersQuery,
  useGetDailyTotalOrderAndItemsQuery,
  useDailyOrderItemsQuery,
  useAllCartItemsAndMemoQuery,
  useGetAuctionResultListQuery,
  useGetAuctionResultDetailsQuery,
  useGetWholesalerSalesStatusQuery,
} from '../graphql/graphql';

export const useConnectableWholesalers = () => {
  const { data, loading, error } = useConnectableWholesalersQuery();

  const connectableWholesalers = useMemo(() => {
    if (data) {
      return data.getConnectableUsers;
    } else {
      return [];
    }
  }, [data]);

  return { connectableWholesalers, loading, error };
};

export const useOrderSheets = (date?: string) => {
  const { data, loading, error } = useOrderSheetsQuery(
    date ? { variables: { filter: { date } } } : undefined,
  );

  const orderSheets = useMemo(() => {
    if (data) {
      return data.getOrderSheets;
    } else {
      return [];
    }
  }, [data]);

  return { orderSheets, loading, error };
};

export const useOrderSheet = (orderSheetId: number) => {
  const { data, loading, error, refetch } = useGetOrderSheetQuery({
    variables: {
      orderSheetId,
    },
    fetchPolicy: 'no-cache',
  });

  const orderSheet = useMemo(() => {
    if (data) {
      return data.getOrderSheet;
    } else {
      return null;
    }
  }, [data]);

  return { orderSheet, loading, error, refetchOrderSheet: refetch };
};

export const useAllCartItemsAndMemo = () => {
  const { data, loading, error, refetch } = useAllCartItemsAndMemoQuery({
    variables: { pagination: { sort: Sort.Descend, size: 100 } },
    fetchPolicy: 'no-cache',
  });

  const memo = useMemo(() => {
    if (data && data.getShoppingSession) {
      return data.getShoppingSession.memo;
    } else {
      return '';
    }
  }, [data]);

  const cartItems = useMemo(() => {
    if (data && data.getShoppingSession) {
      return data.getShoppingSession.cartItems.items;
    } else {
      return [];
    }
  }, [data]);

  const resultCount = useMemo(() => {
    if (data && data.getShoppingSession) {
      return data.getShoppingSession.cartItems.resultCount;
    } else {
      return 0;
    }
  }, [data]);

  return { memo, cartItems, resultCount, loading, error, refetchCartItems: refetch };
};

export const useMe = () => {
  const { data, loading, error } = useMeQuery();

  const me = useMemo(() => {
    if (data) {
      return data.me;
    } else {
      return null;
    }
  }, [data]);

  return { me, loading, error };
};

export const useGetFlowers = (keyword?: string, page?: number) => {
  const { data, loading, error } = useGetFlowersQuery({
    variables: {
      pagination: { page },
      filter: {
        dateRange:
          keyword?.length && keyword?.length > 0
            ? null
            : {
                fromDate: dayjs().subtract(7, 'days').format(DATE_FORMAT),
                toDate: dayjs().format(DATE_FORMAT),
              },
        keyword: keyword,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const flowers = useMemo(() => {
    if (data) {
      return data.getFlowers.items;
    } else {
      return [];
    }
  }, [data]);

  const resultCount = useMemo(() => {
    if (data) {
      return data.getFlowers.resultCount;
    } else {
      return 0;
    }
  }, [data]);

  return { flowers, loading, error, resultCount };
};

export const useGetDailyTotalOrderAndItems = (date: string) => {
  const { data, error, loading } = useGetDailyTotalOrderAndItemsQuery({ variables: { date } });

  const totalOrder = useMemo(() => {
    if (data) {
      return data.getDailyOrderSheetAggregate;
    } else {
      return null;
    }
  }, [data]);

  const orderItems = useMemo(() => {
    if (data) {
      return data.getOrderItems;
    } else {
      return [];
    }
  }, [data]);

  return { totalOrder, orderItems, loading, error };
};

export const useGetWholesaleCustomer = () => {
  const { data, loading, error } = useGetWholesaleCustomersQuery();
  const customers = useMemo(() => {
    if (data) {
      return data.me.receivedConnections;
    } else {
      return null;
    }
  }, [data]);

  return { customers, loading, error };
};

export const useGetDailyOrderSheets = () => {
  const { data, loading, error } = useGetDailyOrderSheetsQuery();

  const dailyOrderSheets = useMemo(() => {
    if (data) {
      return data.getAllDailyOrderSheetAggregates;
    } else {
      return [];
    }
  }, [data]);

  return { dailyOrderSheets, loading, error };
};

export const useMyWholesalers = () => {
  const { data, loading, error } = useMyWholesalersQuery({
    variables: { filter: { applyStatus: [ApplyStatus.Confirmed] } },
  });

  const myWholesalers = useMemo(() => {
    if (data) {
      return data.me.appliedConnections;
    } else {
      return [];
    }
  }, [data]);

  return { myWholesalers, loading, error };
};

export const useCartWholesaler = () => {
  const { data, loading, error } = useCartWholesalerQuery();

  const cartWholesaler = useMemo(() => {
    if (data) {
      return data.getShoppingSession?.wholesaler;
    } else {
      return null;
    }
  }, [data]);

  return { cartWholesaler, loading, error };
};

export const useDailyOrderItems = () => {
  const { data, loading, error } = useDailyOrderItemsQuery({
    variables: {
      filter: {
        dateRange: { fromDate: dayjs().format(DATE_FORMAT), toDate: dayjs().format(DATE_FORMAT) },
      },
    },
  });
  const dailyOrderItems = useMemo(() => {
    if (data) {
      return data.getDailyOrderItems;
    } else {
      return [];
    }
  }, [data]);

  return { dailyOrderItems, loading, error };
};

export const useAuctionResultList = (userId: number, page?: number) => {
  const { data, loading, error, refetch } = useGetAuctionResultListQuery({
    variables: { filter: { wholesalerId: userId }, pagination: { page, sort: Sort.Descend } },
  });

  const auctionResultList = useMemo(() => {
    if (data) {
      return data.auctionResult.items;
    } else {
      return [];
    }
  }, [data]);

  return { auctionResultList, loading, error, refetch };
};

export const useWholesalerSalesStatusList = (wholesalerId: number, page?: number) => {
  const { data, loading, error, refetch } = useGetWholesalerSalesStatusQuery({
    variables: { filter: { wholesalerId }, pagination: { page } },
  });

  const salesStatusList = useMemo(() => {
    if (data) {
      return data.auctionResultForSale.items;
    } else {
      return [];
    }
  }, [data]);

  return { salesStatusList, loading, error, refetch };
};

export const useAuctionResultDetails = (id: number) => {
  const { data, loading, error } = useGetAuctionResultDetailsQuery({
    variables: { filter: { id } },
  });
  const auctionResultDetails = useMemo(() => {
    if (data) {
      return data.auctionResultDetail.item;
    } else {
      return null;
    }
  }, [data]);

  return { auctionResultDetails, loading, error };
};
