import React from 'react';

import { ReactComponent as CartIcon } from '@icons/cart.svg';

import { useAllCartItemsAndMemo } from '@src/shared/hooks';

interface CartProps {
  onCartClick: () => void;
}

export default function CartComponent({ onCartClick }: CartProps) {
  const { resultCount } = useAllCartItemsAndMemo();

  return (
    <div className="relative w-[48px]">
      <CartIcon onClick={onCartClick} className="w-full cursor-pointer" />
      <span className="absolute w-[12px] h-[12px] bottom-0 right-2 bg-primary-500 text-white text-[7px] rounded-full leading-[12px]">
        {resultCount}
      </span>
    </div>
  );
}
