import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import ModalContainer from '../modal/ModalContainer';
import ToastContainer from '../toast/ToastContainer';

export default function Layout() {
  const setViewHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    setViewHeight();
    window.addEventListener('resize', setViewHeight);

    return () => {
      window.removeEventListener('resize', setViewHeight);
    };
  }, []);

  return (
    <div className="flex-center h-screen print:h-auto">
      <div className="w-[600px] h-full bg-white overflow-auto sm:w-full relative">
        <Outlet />
        <ModalContainer />
        <ToastContainer />
      </div>
    </div>
  );
}
