import React, { ChangeEvent, ChangeEventHandler, KeyboardEventHandler } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { ReactComponent as Search } from '@icons/search.svg';
import { ReactComponent as CloseCircle } from '@icons/close-circle.svg';

import { keywordState } from '@shared/recoil/keyword';

interface SearchBarProps {
  placeholder?: string;
}

export default function SearchBar({ placeholder }: SearchBarProps) {
  const [keyword, setKeyword] = useRecoilState(keywordState);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const clearKeyword = () => {
    setKeyword('');
  };

  const setKeywordState: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setKeyword(e.target.value);
  };

  const onKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' && pathname !== '/retail/order/search/results') {
      navigate('/retail/order/search/results');
    }
  };

  return (
    <div className="relative">
      <Search className="absolute top-3 left-3 w-[20px] h-[20px]" />
      <input
        className="w-full py-2 px-10 border border-gray-300 text-body-lg rounded-8 outline-none"
        onChange={setKeywordState}
        placeholder={placeholder}
        value={keyword}
        onKeyUp={onKeyUp}
      />
      {/* TODO: 타이핑중에만 나타나기 */}
      {/* TODO: 누르면 input value 지우기 */}
      {keyword && (
        <CloseCircle
          className="absolute top-[10px] right-3 cursor-pointer"
          onClick={clearKeyword}
        />
      )}
    </div>
  );
}
