import React from 'react';
import { Outlet } from 'react-router-dom';

import TopNav from '../header/navigation/TopNav';

export default function SubPageLayout() {
  // 뒤로가기 헤더만 있는 페이지 레이아웃
  return (
    <div className="w-full h-screen flex flex-col">
      <TopNav />
      <main className="flex-1 p-4 overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
}
